import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as jose from "jose";
import { useContext, useEffect, useState } from "react";
import { Button, FormGroup } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Footer from "../../../../components/Footer/Footer";
import Navbar from "../../../../components/Navbar/Navbar";
import AuthContext from "../../../../contexts/auth";
import { DataUserContext } from "../../../../contexts/context";

import * as yup from "yup";

import { ModalError } from "../../../../components/ModalError/ModalError";
import { ModalSuccess } from "../../../../components/ModalSuccess/ModalSuccess";
export function AddControleEspecialMaterial() {
  const MySwal = withReactContent(Swal);
  const [TokenDecode, setTokenDecode] = useState("");
  const { userData } = useContext(DataUserContext);
  const [erroPage, setErrorPage] = useState(true);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [CalendarData, setCalendarData] = useState([]);
  const [anoStart, setAnoStart] = useState();
  const [inicioPrevisoes, setinicioPrevisoes] = useState();
  const [fimPrevisoes, setfimPrevisoes] = useState();
  const [Load, setLoad] = useState(false);
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AuthContext);
  const [NewInpult, setNewInpult] = useState(true);
  const [ControleEspecial, setControleEspecial] = useState([]);
  const [Code, setCode] = useState("");
  useEffect(() => {
    setTokenDecode(decodeJWT(state.token));
  }, [state.token]);
  function decodeJWT(token) {
    try {
      const decoded = jose.decodeJwt(token);
      return decoded;
    } catch (error) {
      return null;
    }
  }
  const schema = yup.object().shape({
    nome: yup
      .string()
      .required("O nome é obrigatório")
      .typeError("O nome é obrigatório"),
  });
  async function handleSubmit(event) {
    if (NewInpult) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASEURL}/api/v1.0/materiais/controles-especiais`,
          {
            Nome: event.nome,
          },
          {
            headers: {
              unidade_compradora: 227,
              Authorization: `Bearer ${state.token}`,
            },
          }
        );
        ModalSuccess("O controle especial foi adicionado");

        ActionRetornarButton();
      } catch (error) {
        ModalError(error);
      }
    } else {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_BASEURL}/api/v1.0/materiais/controles-especiais`,
          {
            Codigo: Code,

            Nome: event.nome,
          },
          {
            headers: {
              unidade_compradora: 227,
              Authorization: `Bearer ${state.token}`,
            },
          }
        );
        ModalSuccess("O controle especial foi atualizado");

        ActionRetornarButton();
      } catch (error) {
        console.log(error);

        ModalError(error);
      }
    }
  }
  function ActionRetornarButton() {
    navigate(`/Materiais/Controle-Especial`);
  }
  async function getData(Codigo) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/materiais/controles-especiais/${Codigo}`,
        {
          headers: {
            unidade_compradora: 227,
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      if (response.data) {
        setControleEspecial(response.data);
      }
      // setErrorPage(true);
      setNewInpult(false);
      setLoad(true);
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: `Desculpe, ocorreu um erro ao consumir o serviço. Contate nosso suporte (ERROR ${error?.response?.status}).`,

        backdrop: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }

  useEffect(() => {
    const code = searchParams.get("Code");
    if (code !== null) {
      getData(code);
      setCode(code);
    } else {
      setLoad(true);
    }
  }, []);

  return (
    <>
      {Load ? (
        <>
          <Navbar
            // subtitulo="Módulo de Planejamento"
            NomeLink={"Catálogo de Materiais"}
            link={"/Materiais"}
            titulo="Controles especiais"
          />

          <main className="listagem-Calendario-PCA">
            <h3 className="mt-3"> Cadastrar controles especiais</h3>
            <Formik
              validationSchema={schema}
              validateOnBlur={false}
              initialValues={{
                nome: ControleEspecial.Nome ? ControleEspecial.Nome : "",
              }}
              onSubmit={handleSubmit}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <div className="container pt-3 ">
                    <div className="formAdd gap-5">
                      <FormGroup className="mb-3 p-1 formGroup w-50">
                        <label className="">
                          <span className="d-flex flex-row align-items-center">
                            Nome
                          </span>
                        </label>
                        <Field
                          type="text"
                          className="form-control"
                          name="nome"
                        />
                        <ErrorMessage component="p" name="nome" />
                      </FormGroup>
                    </div>
                  </div>
                  <div className="d-flex flex-row-reverse gap-3 mt-5">
                    <Button type="submit">Salvar</Button>
                    <Button onClick={() => ActionRetornarButton()}>
                      Cancelar
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </main>
          <Footer />
        </>
      ) : (
        <></>
      )}
    </>
  );
}
