import styles from "./styles.scss";

import { useContext, useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import AuthContext from "../../contexts/auth";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { state } = useContext(AuthContext);

  return (
    <>
      <Navbar titulo={`Catálogo de Materiais e Serviços `} linkHome={false} />
      <div className="container mt-5 d-flex gap-5 ">
        <div className="col-sm-6 mb-3 mb-sm-0  ">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Catálogo de Materiais</h5>

              <button
                onClick={() => navigate("/Materiais")}
                target="_blank"
                href="#"
                className="btn btn-primary"
              >
                Acessar
              </button>
            </div>
          </div>
        </div>
        <div className="col-sm-6 ">
          <div className="card ">
            <div className="card-body">
              <h5 className="card-title">Catálogo de Serviços</h5>

              <button
                onClick={() => navigate("/Servicos")}
                target="_blank"
                href="#"
                className="btn btn-primary"
              >
                Acessar
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
