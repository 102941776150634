import styles from "./ListaServicos.scss";

import { useContext, useEffect, useState } from "react";
import { HiPencil } from "react-icons/hi";
import Select from "react-select";
import Footer from "../../../components/Footer/Footer";
import Navbar from "../../../components/Navbar/Navbar";

import axios from "axios";
import { Field, Form, Formik } from "formik";
import { FaEye } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SolicitaCadastroSVG from "../../../assets/icons/magnify-plus-outline 1.svg";
import addSubClassesSVG from "../../../assets/icons/note-plus 1.svg";
import addClassseSVG from "../../../assets/icons/note-plus-outline 1.svg";
import UnidadeMedidaSVG from "../../../assets/icons/pencil-ruler 1.svg";
import { ModalDetail } from "../../../components/ModalDetail/ModalDetail";
import { ModalError } from "../../../components/ModalError/ModalError";
import AuthContext from "../../../contexts/auth";
import { DataUserContext } from "../../../contexts/context";
export default function ListaServicos() {
  const MySwal = withReactContent(Swal);
  // const [TokenDecode, setTokenDecode] = useState("");
  const { userData } = useContext(DataUserContext);
  const [erroPage, setErrorPage] = useState(true);
  const [RequestServicos, setRequestServicos] = useState([]);
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AuthContext);
  const [anosDFDData, setAnosDFDData] = useState([]);
  const [currentItem, setCurrentItem] = useState([]);
  const [showEtapa, setShowEtapa] = useState(false);
  const handleCloseEtapa = () => setShowEtapa(false);
  const [showModalJustificativa, setModalJustificativa] = useState(false);
  const [dataBusca, setDataBusca] = useState();
  const [NumberRequest, setNumberRequest] = useState(0);
  const [statusBusca, setStatusBusca] = useState("");
  const handleCloseModalJustificativa = () => setModalJustificativa(false);
  const handleOpenModalJustificativa = () => setModalJustificativa(true);
  const [showModalDetail, setShowModalDetail] = useState(false);
  const handleCloseModalDetail = () => setShowModalDetail(false);
  const handleOpenModalDetail = () => setShowModalDetail(true);
  const [statusVelue, setValueStatus] = useState();
  const [OptionsClasses, setOptionsClasses] = useState([]);
  const [OptionsSubClasses, setOptionsSubClasses] = useState([]);
  function handleConfirmAgePCA(codigo) {
    navigate(`/Cadastra-DFD?etapa=informacoes-basicas&CodigoAno=${codigo}`);
  }
  const options = [
    { label: "Sim", value: true },
    { label: "Não", value: false },
  ];

  useEffect(() => {
    // setTokenDecode(decodeJWT(state.token));
    console.log(state);
  }, [state]);
  // function decodeJWT(token) {
  //   try {
  //     const decoded = jose.decodeJwt(token);
  //     return decoded;
  //   } catch (error) {
  //     return null;
  //   }
  // }
  async function handleRequestBusca(event) {
    console.log(event);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/servicos/servicos?nome=${
          event.nome
        }&codigoClasse=${
          event.classe?.value ? event.classe?.value : ""
        }&codigoSubclasse=${
          event.subClasse?.value ? event.subClasse?.value : ""
        }&descontinuado=${
          event.descontinuado?.value !== undefined
            ? event.descontinuado?.value
            : ""
        }`,
        {
          headers: {
            unidade_compradora: 227,
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      if (response.data) {
        setRequestServicos(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getData() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/servicos/servicos`,
        {
          headers: {
            unidade_compradora: 227,
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      if (response.data) {
        setRequestServicos(response.data);
      }
      setErrorPage(false);
    } catch (error) {
      setErrorPage(true);
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: `Desculpe, ocorreu um erro ao consumir o serviço. Contate nosso suporte (ERROR ${error?.response?.status}).`,

        backdrop: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }

  async function getDataSubClasses() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/servicos/subclasses`,
        {
          headers: {
            unidade_compradora: 227,
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      if (response.data) {
        const filtro = response.data.filter(
          (item) => item.Status !== "Deletado"
        );
        let Options = filtro.map((item) => {
          return { label: item.Nome, value: item.Codigo };
        });
        setOptionsSubClasses(Options);
      }
    } catch (error) {
      setErrorPage(true);
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: `Desculpe, ocorreu um erro ao consumir o serviço. Contate nosso suporte (ERROR ${error?.response?.status}).`,

        backdrop: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }
  async function getRegistrationRequests() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/materiais/solicitacoes`,
        {
          headers: {
            unidade_compradora: 227,
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      if (response.data) {
        const filtroUser = response.data.filter(
          (item) => item.Status !== "Deletado" && item.StatusAprovacaoId === 4
        );
        const filtroAdmin = response.data.filter(
          (item) => item.Status !== "Deletado" && item.StatusAprovacaoId === 1
        );
        setNumberRequest(
          state.isAdmin ? filtroAdmin.length : filtroUser.length
        );
      }
    } catch (error) {
      setErrorPage(true);
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: `Desculpe, ocorreu um erro ao consumir o serviço. Contate nosso suporte (ERROR ${error?.response?.status}).`,

        backdrop: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }

  async function getDataClasses() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/servicos/classes`,
        {
          headers: {
            unidade_compradora: 227,
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      if (response.data) {
        const filtro = response.data.filter(
          (item) => item.Status !== "Deletado"
        );
        let Options = filtro.map((item) => {
          return { label: item.Nome, value: item.Codigo };
        });
        setOptionsClasses(Options);
      }
      // setErrorPage(true);
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: `Desculpe, ocorreu um erro ao consumir o serviço. Contate nosso suporte (ERROR ${error?.response?.status}).`,

        backdrop: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }
  function RemoveItem(index) {
    MySwal.fire({
      title: "Tem certeza?",
      text: "Você não será capaz de reverter isso!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Não",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, exclua!",
    }).then((result) => {
      if (result.isConfirmed) {
        const items = [...RequestServicos];

        removeApi(items[index].Codigo);
        items.splice(index, 1);

        setRequestServicos(items);

        MySwal.fire("Excluído!", "O Material foi deletado.", "success");
      }
    });
  }

  function removeApi(code) {
    try {
      axios
        .delete(
          `${process.env.REACT_APP_BASEURL}/api/v1.0/servicos/servicos/${code}`,
          {
            headers: {
              unidade_compradora: 227,
              Authorization: `Bearer ${state.token}`,
            },
          }
        )
        .then((res) => {});
    } catch (error) {
      ModalError(error);
    }
  }

  useEffect(() => {
    getRegistrationRequests();
    getData();
    getDataSubClasses();
    getDataClasses();
  }, []);

  return (
    <>
      {!erroPage ? (
        <>
          <Navbar
            NomeLink={"Seleçao de Modulos"}
            link={"/"}
            titulo="Catálogo de Serviços"
          />

          <div className=" container iconsHeader">
            {state.isAdmin && (
              <>
                <div onClick={() => navigate("/Servicos/Classes")}>
                  <img src={addClassseSVG} alt="Logo do +Classe" />
                  <span>Classe</span>
                </div>
                <div onClick={() => navigate("/Servicos/Subclasses")}>
                  <img src={addSubClassesSVG} alt="Logo do +Subclasse" />
                  <span>Subclasse</span>
                </div>
                <div onClick={() => navigate("/Servicos/Unidades-Medida")}>
                  <img src={UnidadeMedidaSVG} alt="Logo do unidade de medida" />
                  <span>Unidade de Medida</span>
                </div>
              </>
            )}
            <div onClick={() => navigate("/Servicos/Solicitacao-Cadastro")}>
              <div className="NotificationImage">
                <img
                  src={SolicitaCadastroSVG}
                  alt="Logo do solicita Cadastro"
                />
                {NumberRequest > 0 ? <span>{NumberRequest}</span> : <></>}
              </div>
              <span>Solicitações de Cadastro</span>
            </div>
          </div>
          <main className="listagem-inicial">
            <Formik
              initialValues={{
                nome: "",

                classe: "",
                subClasse: "",
                descontinuado: "",
              }}
              onSubmit={handleRequestBusca}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <div className="container pt-3   ">
                    <div className="formPCA gap-3 ">
                      <div className="col-sm">
                        <Field
                          type="text"
                          name="nome"
                          placeholder="Nome"
                          className="form-control inputForm "
                        />
                      </div>

                      <div className="col-sm">
                        <Select
                          className="inputSelect"
                          name="classe"
                          options={OptionsClasses}
                          placeholder="Classe"
                          value={values.classe}
                          onChange={(selectedOptions) => {
                            setFieldValue("classe", selectedOptions);
                          }}
                        />
                      </div>
                      <div className="col-sm">
                        <Select
                          className="inputSelect"
                          name="subClasse"
                          options={OptionsSubClasses}
                          placeholder="Subclasse"
                          value={values.subClasse}
                          onChange={(selectedOptions) => {
                            setFieldValue("subClasse", selectedOptions);
                          }}
                        />
                      </div>
                      <div className="col-sm">
                        <Select
                          name="descontinuado"
                          value={values.descontinuado}
                          placeholder="Descontinuado"
                          options={options}
                          onChange={(selectedOptions) => {
                            setFieldValue("descontinuado", selectedOptions);
                          }}
                        />
                      </div>
                      <div className="col-sm">
                        <Field
                          type="text"
                          name="unitRequester"
                          placeholder="Unidade"
                          className="form-control inputForm"
                        />
                      </div>

                      <div className="col">
                        <button className="btn btn-primary " type="submit">
                          Pesquisar
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
            <div className="container">
              <table className="table">
                <tbody>
                  <tr className={styles.linha__tabbel_listagem}>
                    <th>Código</th>
                    <th>Nome</th>
                    <th>Classe</th>
                    <th>Subclasse</th>
                    <th>Descontinuado</th>
                    <th>Ações</th>
                  </tr>

                  {RequestServicos?.map((item, index) => (
                    <tr key={item?.Codigo}>
                      <td>{item?.CodigoMaterial}</td>
                      <td>{item?.Nome}</td>
                      <td>{item.Classe?.Nome}</td>
                      <td>{item.Subclasse?.Nome}</td>
                      <td>{item?.Preco?.Descontinuado ? "sim" : "não"}</td>

                      <td>
                        {!state.isAdmin ? (
                          <>
                            <FaEye
                              type="button"
                              onClick={() => {
                                handleOpenModalDetail();
                                setCurrentItem(item?.Codigo);
                              }}
                            />
                          </>
                        ) : (
                          <div>
                            <HiPencil
                              type="button"
                              name="teste"
                              onClick={() =>
                                navigate(
                                  `/Servicos/Cadastra?Code=${item.Codigo}&Editar=true&Material=true`
                                )
                              }
                            />
                            <MdCancel
                              className="IconImagem"
                              type="button"
                              style={{ color: "red" }}
                              onClick={() => RemoveItem(index)}
                            />
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <ModalDetail
              showModal={showModalDetail}
              handleCloseModal={handleCloseModalDetail}
              itemId={currentItem}
            />
          </main>
          <Footer />
        </>
      ) : (
        <></>
      )}
    </>
  );
}
