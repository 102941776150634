import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { DatePicker } from "@mui/x-date-pickers";
import axios from "axios";
import dayjs from "dayjs";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Button, FormGroup } from "react-bootstrap";
import { MdCancel } from "react-icons/md";
import ReactQuill from "react-quill";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import * as yup from "yup";
import Footer from "../../../components/Footer/Footer";
import { ModalError } from "../../../components/ModalError/ModalError";
import { ModalErrorMessage } from "../../../components/ModalErrorMessage /ModalErrorMessage ";
import Navbar from "../../../components/Navbar/Navbar";
import AuthContext from "../../../contexts/auth";
import { FormatBytes } from "../../../functions/FormatBytes";
import "./CadastroMaterial.scss";
export function CadastroMaterial() {
  const [Load, setLoad] = useState(false);
  const navigate = useNavigate();
  const [dataEditor, setDataEditor] = useState("");
  const inputRef = React.createRef();
  const inputImageRef = React.createRef();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showEtapa, setShowEtapa] = useState(false);
  const handleCloseEtapa = () => setShowEtapa(false);
  const { state } = useContext(AuthContext);
  const [OptionsUnidadeDeMedida, setOptionsUnidadeDeMedida] = useState([]);
  const [uploadFile, setUploadFile] = useState(false);
  const [uploadImage, setUploadImage] = useState(false);
  const [OptionsClasses, setOptionsClasses] = useState([]);
  const [OptionsSubClasses, setOptionsSubClasses] = useState([]);
  const [SubClassesData, setSubClassesData] = useState([]);
  const [SelectClasses, setSelectClasses] = useState([]);
  const [OptionsControleEspecial, setOptionsControleEspecial] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [DataMateriais, setDataMateriais] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [EditMaterial, setEditMaterial] = useState(false);
  const [NewInpult, setNewInpult] = useState(true);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [Code, setCode] = useState("");
  const [Editar, setEditar] = useState(false);
  const MySwal = withReactContent(Swal);
  const schemaAdmin = yup.object().shape({
    codigoMaterial: yup
      .string()
      .typeError("O Valor deve ser um número")
      .required("O número do Código Externo é obrigatório"),
    unidadeMedidaSelect: yup
      .object()
      .typeError("A Unidade de medida e Obrigatória")
      .required("A Unidade de medida e Obrigatória"),
    nome: yup
      .string()
      .typeError("O Nome deve ser um texto")
      .required("O Nome  é obrigatório"),
    classeSelect: yup
      .object()
      .typeError("A classe deve ser selecionada")
      .required("A classe é Obrigatória"),

    subClasseSelect: yup.object().typeError("A Subclasse deve ser selecionada"),
    tipoMaterialSelect: yup
      .object()
      .typeError("O Tipo de Material deve ser selecionado")
      .required("O Tipo de Material é obrigatório"),

    periculosidadeSelect: yup
      .object()
      .typeError("A Periculosidade deve ser selecionada")
      .required("A Periculosidade é obrigatória"),

    controleEspecialSelect: yup
      .object()
      .typeError("O controle especial  deve ser selecionada")
      .required("O controle especial é obrigatório"),
    valorUnitario: yup.number().typeError("O Valor deve ser um número"),
    dataInicial: yup.object().typeError("O Valor deve ser uma data"),
    validade: yup.object().typeError("O Valor deve ser uma data"),
    frequenciaSelect: yup
      .object()
      .typeError("O Valor deve ser selecionado")
      .required("A frequência é obrigatória"),
  });
  const SchemaUser = yup.object().shape({
    nome: yup
      .string()
      .typeError("O Nome deve ser um texto")
      .required("O Nome  é obrigatório"),
  });
  const optionsPericulosidade = [
    { label: "Não se aplica", value: 1 },
    { label: "Corrosivo", value: 2 },
    { label: "Explosivo", value: 3 },
    { label: "Inflamável", value: 4 },
    { label: "Reação Alérgica", value: 5 },
    { label: "Tóxico", value: 6 },
  ];
  const optionsMaterial = [
    { label: "Consumo", value: 1 },
    { label: "Permanente Inventariável", value: 2 },
    { label: "Permanente Não Inventariável", value: 3 },
    { label: "Reposição Fabricação Própria", value: 4 },
  ];
  const OptionsFrequencia = [
    { label: "Semanal", value: 1 },
    { label: "Quinzenal", value: 2 },
    { label: "Mensall", value: 3 },
    { label: "Trimestral", value: 4 },
    { label: "Semestral", value: 5 },
    { label: "Anual", value: 6 },
    { label: "Bienal", value: 7 },
  ];
  async function handleSubmit(event) {
    const imagesSubmitted = selectedImages?.map((item) => {
      return item.Codigo;
    });
    const FilesSubmitted = selectedFiles?.map((item) => {
      return item.Codigo;
    });
    if (NewInpult) {
      try {
        const response = await axios.post(
          state.isAdmin
            ? `${process.env.REACT_APP_BASEURL}/api/v1.0/materiais/materiais`
            : `${process.env.REACT_APP_BASEURL}/api/v1.0/materiais/solicitacoes`,
          {
            CodigoUnidadeRequisitante: "56b10c85-a8ce-4f02-9833-3af02bcf4ede",
            CodigoMaterial: event.codigoMaterial,
            Nome: event.nome,
            TipoMaterial: event.tipoMaterialSelect?.value,
            Periculosidade: event.periculosidadeSelect?.value,
            Descricao: dataEditor,
            CodigoClasse: event.classeSelect.value,
            CodigoSubclasse: event.subClasseSelect.value,
            CodigoControleEspecial: event.controleEspecialSelect?.value,
            CodigoUnidadeMedida: event.unidadeMedidaSelect.value,
            Preco: {
              ValorUnitario: event.valorUnitario,
              DataInicial:
                event.dataInicial !== ""
                  ? event.dataInicial?.format("YYYY-MM-DDTHH:mm:ss.SSSZ")
                  : "",
              Frequencia:
                event?.frequenciaSelect !== ""
                  ? event?.frequenciaSelect?.value
                  : undefined,
              Validade:
                event.validade !== ""
                  ? event.validade?.format("YYYY-MM-DDTHH:mm:ss.SSSZ")
                  : "",
              Descontinuado: event.descontinuadoCheck,
              Registro: event.registroCheck,
              MaterialEspecifico: event.EspecificoCheck,
            },
            Imagens: imagesSubmitted?.length ? imagesSubmitted : "",
            EspecificacoesComplementares: FilesSubmitted?.length
              ? FilesSubmitted
              : "",
          },
          {
            headers: {
              unidade_compradora: 227,
              Authorization: `Bearer ${state.token}`,
            },
          }
        );
        // setErrorPage(true);
        navigate(`/Materiais`);
      } catch (error) {
        ModalErrorMessage(error);
      }
    } else {
      try {
        console.log(event);
        const response = await axios.put(
          EditMaterial
            ? `${process.env.REACT_APP_BASEURL}/api/v1.0/materiais/materiais`
            : `${process.env.REACT_APP_BASEURL}/api/v1.0/materiais/solicitacoes/analisar`,
          {
            Codigo: Code,
            StatusAprovacao: state.isAdmin ? 3 : 1,

            CodigoUnidadeRequisitante: "56b10c85-a8ce-4f02-9833-3af02bcf4ede",
            CodigoMaterial: event.codigoMaterial,
            Nome: event.nome,
            TipoMaterial: event.tipoMaterialSelect?.value,
            Periculosidade: event.periculosidadeSelect?.value,
            Descricao: dataEditor,
            CodigoClasse: event.classeSelect.value,
            CodigoSubclasse: event.subClasseSelect.value,
            CodigoControleEspecial: event.controleEspecialSelect?.value,
            CodigoUnidadeMedida: event.unidadeMedidaSelect.value,
            Preco: {
              Codigo: DataMateriais?.Preco?.Codigo,
              ValorUnitario: event.valorUnitario,
              DataInicial:
                event.dataInicial !== ""
                  ? event.dataInicial?.format("YYYY-MM-DDTHH:mm:ss.SSSZ")
                  : "",
              Frequencia:
                event?.frequenciaSelect !== ""
                  ? event?.frequenciaSelect?.value
                  : "",
              Validade:
                event.validade !== ""
                  ? event.validade?.format("YYYY-MM-DDTHH:mm:ss.SSSZ")
                  : "",
              Descontinuado: event.descontinuadoCheck,
              Registro: event.registroCheck,
              MaterialEspecifico: event.EspecificoCheck,
            },
            Imagens: imagesSubmitted?.length ? imagesSubmitted : "",
            EspecificacoesComplementares: FilesSubmitted?.length
              ? FilesSubmitted
              : "",
          },
          {
            headers: {
              unidade_compradora: 227,
              Authorization: `Bearer ${state.token}`,
            },
          }
        );
        navigate(`/Materiais`);
        // setErrorPage(true);
      } catch (error) {
        console.log(error);

        ModalErrorMessage(error);
      }
    }
  }

  function ActionRetornarButton() {
    MySwal.fire({
      title: "Tem certeza?",
      text: "Alguns dados podem não estar salvos!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Não",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, Sair!",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate(`/Materiais`);
      }
    });
  }
  const handleImagensChange = (event) => {
    setUploadImage(true);
    const files = event.target.files;
    console.log(files);
    // Mapear cada arquivo e processar
    const newFiles = Array.from(files).map((file) => {
      // Verificar se o arquivo é uma imagem
      if (file.type.startsWith("image/")) {
        const reader = new FileReader();

        reader.onloadend = () => {
          // Atualizar o estado para incluir a prévia da imagem
          setImagePreviews((prevPreviews) => [...prevPreviews, reader.result]);
        };

        reader.readAsDataURL(file);
      } else {
        // Caso o arquivo não seja uma imagem, exibir uma mensagem de erro
        alert("Por favor, selecione um arquivo de imagem válido.");
      }

      return {
        name: file?.name,
        size: file?.size,
      };
    });

    // Atualizar o estado para incluir os novos arquivos
    // setSelectedImages((prevFiles) => [...prevFiles, ...newFiles]);
    handleUploadImage(files[0]);
    // setUploadImage(false);
  };
  const handleFileChange = (event) => {
    setUploadFile(true);
    const files = event.target.files;

    let data = {
      name: files?.name,
      size: files?.fase,
      // codigoFile: res.Codigo,
      // CodigoETP: res.CodigoETP,
      // CodigoExterno: res.CodigoExterno,
      // Status: res.Status,
    };

    // Atualizar o estado para incluir os novos arquivos
    setSelectedFiles([...selectedFiles, data]);
    handleUploadFile(files[0]);
  };
  const handleButtonClick = () => {
    inputRef.current.click();
  };
  const handleButtonImageClick = () => {
    inputImageRef.current.click();
  };
  function RemoveItem(index) {
    MySwal.fire({
      title: "Tem certeza?",
      text: "Você não será capaz de reverter isso!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Não",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, exclua!",
    }).then((result) => {
      if (result.isConfirmed) {
        const items = [...selectedFiles];

        removeFileApi(items[index].Codigo);
        items.splice(index, 1);

        //limpa input para receber o mesmo arquivo
        setSelectedFiles(items);
        if (inputRef.current) {
          inputRef.current.value = null;
        }

        MySwal.fire("Excluído!", "Seu arquivo foi deletado.", "success");
      }
    });
  }
  function RemoveImage(index) {
    MySwal.fire({
      title: "Tem certeza?",
      text: "Você não será capaz de reverter isso!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Não",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, exclua!",
    }).then((result) => {
      if (result.isConfirmed) {
        const items = [...selectedImages];

        removeFileApi(items[index].Codigo);
        items.splice(index, 1);

        //limpa input para receber o mesmo arquivo
        setSelectedImages(items);
        if (inputImageRef.current) {
          inputImageRef.current.value = null;
        }
        const item = [...imagePreviews];

        item.splice(index, 1);

        //limpa input para receber o mesmo arquivo
        setImagePreviews(item);

        MySwal.fire("Excluído!", "Seu arquivo foi deletado.", "success");
      }
    });
  }
  function removeFileApi(code) {
    try {
      axios
        .delete(
          `${process.env.REACT_APP_BASEURL}/api/v1.0/materiais/anexos/${code}`,
          {
            headers: {
              unidade_compradora: 227,
              Authorization: `Bearer ${state.token}`,
            },
          }
        )
        .then((res) => {});
    } catch (error) {
      ModalError(error);
    }
  }

  async function handleUploadImage(file) {
    const formData = new FormData();
    formData.append("arquivos", file);
    // formData.append("CodigoETP", code);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/materiais/anexos`,
        {
          method: "POST",
          body: formData,
          headers: {
            unidade_compradora: 227,
            Authorization: `Bearer ${state.token}`,
          },
        }
      );

      const resposta = await response.json();
      const res = resposta[0];

      if (res) {
        let file = {
          name: res?.Nome + "." + res.Extensao,
          size: res?.Tamanho,
          Url: res?.Url,
          Codigo: res.Codigo,
          Status: res.Status,
        };

        let temList = selectedImages;
        temList[selectedImages.length] = file;

        setSelectedImages(temList);
      }
      setUploadImage(false);
    } catch (error) {
      ModalError(error);
    }
  }
  async function handleUploadFile(file) {
    const formData = new FormData();
    formData.append("arquivos", file);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/materiais/anexos`,
        {
          method: "POST",
          body: formData,
          headers: {
            unidade_compradora: 227,
            Authorization: `Bearer ${state.token}`,
          },
        }
      );

      const resposta = await response.json();
      const res = resposta[0];

      if (res) {
        let file = {
          name: res?.Nome + "." + res.Extensao,
          size: res?.Tamanho,
          Codigo: res.Codigo,
          Status: res.Status,
        };

        let temList = selectedFiles;
        temList[selectedFiles.length] = file;

        setSelectedFiles(temList);
      }
      setUploadFile(false);
    } catch (error) {
      ModalError(error);
    }
  }
  async function getDataSubClasses() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/materiais/subclasses`,
        {
          headers: {
            unidade_compradora: 227,
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      if (response.data) {
        const filtro = response.data.filter(
          (item) => item.Status !== "Deletado"
        );
        // let Options = filtro.map((item) => {
        //   return { label: item.Nome, value: item.Codigo };
        // });
        setSubClassesData(filtro);
      }
      // setErrorPage(true);
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: `Desculpe, ocorreu um erro ao consumir o serviço. Contate nosso suporte (ERROR ${error?.response?.status}).`,

        backdrop: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }
  async function getUnidadeMedida() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/materiais/unidades-de-medida`,
        {
          headers: {
            unidade_compradora: 227,
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      if (response.data) {
        const filtro = response.data.filter(
          (item) => item.Status !== "Deletado"
        );
        let Options = filtro.map((item) => {
          return { label: item.Sigla, value: item.Codigo };
        });
        setOptionsUnidadeDeMedida(Options);
      }
      // setErrorPage(true);
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: `Desculpe, ocorreu um erro ao consumir o serviço. Contate nosso suporte (ERROR ${error?.response?.status}).`,

        backdrop: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }

  async function getDataClasses() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/materiais/classes`,
        {
          headers: {
            unidade_compradora: 227,
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      if (response.data) {
        const filtro = response.data.filter(
          (item) => item.Status !== "Deletado"
        );
        let Options = filtro.map((item) => {
          return { label: item.Nome, value: item.Codigo };
        });
        setOptionsClasses(Options);
      }
      // setErrorPage(true);
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: `Desculpe, ocorreu um erro ao consumir o serviço. Contate nosso suporte (ERROR ${error?.response?.status}).`,

        backdrop: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }
  async function getDataControleEspecial() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/materiais/controles-especiais`,
        {
          headers: {
            unidade_compradora: 227,
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      if (response.data) {
        const filtro = response.data.filter(
          (item) => item.Status !== "Deletado"
        );
        let Options = filtro.map((item) => {
          return { label: item.Nome, value: item.Codigo };
        });
        setOptionsControleEspecial(Options);
      }
      // setErrorPage(true);
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: `Desculpe, ocorreu um erro ao consumir o serviço. Contate nosso suporte (ERROR ${error?.response?.status}).`,

        backdrop: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }

  async function getData() {
    getUnidadeMedida();
    getDataClasses();
    getDataSubClasses();
    getDataControleEspecial();
  }
  async function getDataMateriais(code) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/materiais/materiais/${code}`,
        {
          headers: {
            unidade_compradora: 227,
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      if (response.data) {
        setDataMateriais(response.data);
        setDataEditor(response.data?.Descricao);
        let dataRes = response.data?.EspecificacoesComplementares?.map(
          (item) => ({
            name: item?.Nome + "." + item.Extensao,
            size: item?.Tamanho,
            Codigo: item?.Codigo,
          })
        );

        setSelectedFiles(dataRes ? dataRes : []);
        let ImagensRes = response.data.Imagens?.map((item) => ({
          name: item?.Nome + "." + item.Extensao,
          size: item?.Tamanho,
          Codigo: item?.Codigo,
          Url: item?.Url,
        }));
        setSelectedImages(ImagensRes ? ImagensRes : []);
        setImagePreviews(ImagensRes ? ImagensRes : []);
      }
      setNewInpult(false);
      setLoad(true);
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: `Desculpe, ocorreu um erro ao consumir o serviço. Contate nosso suporte (ERROR ${error?.response?.status}).`,

        backdrop: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }
  async function getDataRequest(code) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/materiais/solicitacoes/${code}`,
        {
          headers: {
            unidade_compradora: 227,
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      if (response.data) {
        setDataMateriais(response.data.Material);
        setDataEditor(response.data?.Material?.Descricao);
        let dataRes =
          response.data?.Material?.EspecificacoesComplementares?.map(
            (item) => ({
              name: item?.Nome + "." + item.Extensao,
              size: item?.Tamanho,
              Codigo: item?.Codigo,
            })
          );

        setSelectedFiles(dataRes ? dataRes : []);
        let ImagensRes = response.data?.Material?.Imagens?.map((item) => ({
          name: item?.Nome + "." + item.Extensao,
          size: item?.Tamanho,
          Codigo: item?.Codigo,
          Url: item?.Url,
        }));
        setSelectedImages(ImagensRes ? ImagensRes : []);
        setImagePreviews(ImagensRes ? ImagensRes : []);
      }
      setNewInpult(false);
      setLoad(true);
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: `Desculpe, ocorreu um erro ao consumir o serviço. Contate nosso suporte (ERROR ${error?.response?.status}).`,

        backdrop: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }
  useEffect(() => {
    const code = searchParams.get("Code");
    const edit = searchParams.get("Editar");
    const editMaterial = searchParams.get("Material");
    if (editMaterial) {
      setEditMaterial(true);
    }
    if (edit) {
      setEditar(true);
    } else {
      setEditar(false);
    }
    if (code !== null) {
      getData();
      if (!editMaterial) {
        getDataRequest(code);
      } else {
        getDataMateriais(code);
      }
      setCode(code);
    } else {
      setLoad(true);
      getData();
    }
  }, []);

  useEffect(() => {
    const filtro = SubClassesData?.filter(
      (item) => item.Classe.Codigo === SelectClasses.value
    );
    let Options = filtro?.map((item) => {
      return { label: item.Nome, value: item.Codigo };
    });

    setOptionsSubClasses(Options);
  }, [SelectClasses]);

  function NavigateButtons() {
    var textButtonConfirm;
    if (state.isAdmin) {
      if (Editar) {
        textButtonConfirm = "Editar";
      } else if (Code) {
        textButtonConfirm = "Aprovar";
      } else textButtonConfirm = "Salvar";
    } else {
      if (Editar) {
        textButtonConfirm = "Editar";
      } else textButtonConfirm = "Enviar para análise";
    }

    return (
      <div className="d-flex flex-row-reverse gap-3 mt-5">
        <Button type="submit" disabled={uploadFile || uploadImage}>
          {textButtonConfirm}
        </Button>
        {Code && Editar !== true && state.isAdmin ? (
          <Button
            className="btn-Cancel"
            type="submit"
            disabled={uploadFile || uploadImage}
          >
            Rejeitar
          </Button>
        ) : (
          <></>
        )}
        <Button onClick={() => ActionRetornarButton()}>Voltar</Button>
      </div>
    );
  }
  function SelectSchemaUser() {
    if (state.isAdmin) {
      return schemaAdmin;
    } else {
      return SchemaUser;
    }
  }
  return (
    <>
      {Load ? (
        <>
          <Navbar
            // subtitulo="Módulo de Planejamento"
            NomeLink="Catálogo de Materiais"
            link="/Materiais"
            titulo="Cadastrar Material"
          />

          <main className="listagem-Calendario-PCA">
            <h3 className="mt-3">Dados Gerais</h3>
            <Formik
              validationSchema={SelectSchemaUser}
              validateOnBlur={false}
              initialValues={{
                codigoMaterial: DataMateriais?.CodigoMaterial
                  ? DataMateriais.CodigoMaterial
                  : "",
                unidadeMedidaSelect: DataMateriais?.UnidadeMedida
                  ? {
                      label: DataMateriais.UnidadeMedida.Nome,
                      value: DataMateriais.UnidadeMedida.Codigo,
                    }
                  : "",
                nome: DataMateriais?.Nome ? DataMateriais.Nome : "",
                classeSelect: DataMateriais?.Classe
                  ? {
                      label: DataMateriais.Classe.Nome,
                      value: DataMateriais.Classe.Codigo,
                    }
                  : "",
                subClasseSelect: DataMateriais?.Subclasse
                  ? {
                      label: DataMateriais.Subclasse?.Nome,
                      value: DataMateriais.Subclasse?.Codigo,
                    }
                  : "",
                tipoMaterialSelect: DataMateriais?.TipoMaterial
                  ? {
                      label: DataMateriais.TipoMaterial,
                      value: DataMateriais.TipoMaterialId,
                    }
                  : "",
                periculosidadeSelect: DataMateriais?.Periculosidade
                  ? {
                      label: DataMateriais.Periculosidade,
                      value: DataMateriais.PericulosidadeId,
                    }
                  : "",
                controleEspecialSelect: DataMateriais?.ControleEspecial
                  ? {
                      label: DataMateriais.ControleEspecial.Nome,
                      value: DataMateriais.ControleEspecial.Codigo,
                    }
                  : "",
                // ===============PREÇO ==========================
                valorUnitario: DataMateriais?.Preco?.ValorUnitario
                  ? DataMateriais?.Preco?.ValorUnitario
                  : "",
                dataInicial: DataMateriais?.Preco?.DataInicial
                  ? dayjs(DataMateriais?.Preco?.DataInicial)
                  : undefined,
                frequenciaSelect: DataMateriais?.Preco?.Frequencia
                  ? {
                      label: DataMateriais?.Preco.Frequencia,
                      value: DataMateriais?.Preco.FrequenciaId,
                    }
                  : "",
                validade: DataMateriais?.Preco?.Validade
                  ? dayjs(DataMateriais?.Preco?.Validade)
                  : undefined,
                descontinuadoCheck: DataMateriais?.Preco?.Descontinuado
                  ? DataMateriais?.Preco?.Descontinuado
                  : false,
                registroCheck: DataMateriais?.Preco?.Registro
                  ? DataMateriais?.Preco?.Registro
                  : false,
                EspecificoCheck: DataMateriais?.Preco?.MaterialEspecifico
                  ? DataMateriais?.Preco?.MaterialEspecifico
                  : false,
              }}
              onSubmit={handleSubmit}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <div className="container pt-3 ">
                    <div className="formAddMaterialHorizontal ">
                      <div className="formAddMaterialVertical">
                        {state.isAdmin || Editar ? (
                          <FormGroup className="mb-3 p-1 formGroup  w-75">
                            <label className="">
                              <span className="d-flex flex-row align-items-center">
                                Código
                              </span>
                            </label>
                            <Field
                              type="text"
                              className="form-control"
                              name="codigoMaterial"
                            />
                            <ErrorMessage component="p" name="codigoMaterial" />
                          </FormGroup>
                        ) : (
                          <FormGroup className="mb-3 p-1 formGroup w-75">
                            <label className="">
                              <span className="d-flex flex-row align-items-center">
                                Nome
                              </span>
                            </label>
                            <Field
                              disabled={Editar}
                              type="text"
                              className="form-control"
                              name="nome"
                            />
                            <ErrorMessage component="p" name="nome" />
                          </FormGroup>
                        )}
                        <FormGroup className="mb-3 p-1 formGroup w-75">
                          <label className="">
                            <span className="d-flex flex-row align-items-center">
                              Unidade de Medida
                            </span>
                          </label>

                          <Select
                            className="inputSelect"
                            name="unidadeMedidaSelect"
                            options={OptionsUnidadeDeMedida}
                            placeholder="Selecione"
                            value={values.unidadeMedidaSelect}
                            onChange={(selectedOptions) => {
                              setFieldValue(
                                "unidadeMedidaSelect",
                                selectedOptions
                              );
                            }}
                          />
                          <ErrorMessage
                            component="p"
                            name="unidadeMedidaSelect"
                          />
                        </FormGroup>
                        <FormGroup className="mb-3 p-1 formGroup w-75">
                          <label className="">
                            <span className="d-flex flex-row align-items-center">
                              Classe
                            </span>
                          </label>
                          <Select
                            className="inputSelect"
                            name="classeSelect"
                            options={OptionsClasses}
                            placeholder="Selecione"
                            value={values.classeSelect}
                            onChange={(selectedOptions) => {
                              setFieldValue("classeSelect", selectedOptions);
                              setSelectClasses(selectedOptions);
                            }}
                          />
                          <ErrorMessage component="p" name="classeSelect" />
                        </FormGroup>
                        <FormGroup className="mb-3 p-1 formGroup w-75">
                          <label className="">
                            <span className="d-flex flex-row align-items-center">
                              Periculosidade
                            </span>
                          </label>
                          <Select
                            className="inputSelect"
                            name="periculosidadeSelect"
                            options={optionsPericulosidade}
                            placeholder="Selecione"
                            value={values.periculosidadeSelect}
                            onChange={(selectedOptions) => {
                              setFieldValue(
                                "periculosidadeSelect",
                                selectedOptions
                              );
                            }}
                          />
                          <ErrorMessage
                            component="p"
                            name="periculosidadeSelect"
                          />
                        </FormGroup>
                      </div>
                      <div className="formAddMaterialVertical align-items-end">
                        {(state.isAdmin || Editar) && (
                          <FormGroup className="mb-3 p-1 formGroup w-75">
                            <label className="">
                              <span className="d-flex flex-row align-items-center">
                                Nome
                              </span>
                            </label>
                            <Field
                              type="text"
                              className="form-control"
                              name="nome"
                            />
                            <ErrorMessage component="p" name="nome" />
                          </FormGroup>
                        )}
                        <FormGroup className="mb-3 p-1 formGroup w-75">
                          <label className="">
                            <span className="d-flex flex-row align-items-center">
                              Tipo de Material
                            </span>
                          </label>
                          <Select
                            className="inputSelect"
                            name="tipoMaterialSelect"
                            options={optionsMaterial}
                            placeholder="Selecione"
                            value={values.tipoMaterialSelect}
                            onChange={(selectedOptions) => {
                              setFieldValue(
                                "tipoMaterialSelect",
                                selectedOptions
                              );
                            }}
                          />
                          <ErrorMessage
                            component="p"
                            name="tipoMaterialSelect"
                          />
                        </FormGroup>
                        <FormGroup className="mb-3 p-1 formGroup w-75">
                          <label className="">
                            <span className="d-flex flex-row align-items-center">
                              Subclasse
                            </span>
                          </label>
                          <Select
                            className="inputSelect"
                            name="subClasseSelect"
                            options={OptionsSubClasses}
                            placeholder="Selecione"
                            value={values.subClasseSelect}
                            onChange={(selectedOptions) => {
                              setFieldValue("subClasseSelect", selectedOptions);
                            }}
                          />
                          <ErrorMessage component="p" name="subClasseSelect" />
                        </FormGroup>
                        <FormGroup className="mb-3 p-1 formGroup w-75">
                          <label className="">
                            <span className="d-flex flex-row align-items-center">
                              Controle Especial
                            </span>
                          </label>
                          <Select
                            className="inputSelect"
                            name="controleEspecialSelect"
                            options={OptionsControleEspecial}
                            placeholder="Selecione"
                            value={values.controleEspecialSelect}
                            onChange={(selectedOptions) => {
                              setFieldValue(
                                "controleEspecialSelect",
                                selectedOptions
                              );
                            }}
                          />
                          <ErrorMessage
                            component="p"
                            name="controleEspecialSelect"
                          />
                        </FormGroup>
                      </div>
                    </div>
                    <label className="">
                      <span className="d-flex flex-row align-items-center">
                        Informações Adicionais
                      </span>
                    </label>
                    <div className="mb-5">
                      <ReactQuill
                        className="sizeEditor"
                        name="text"
                        value={dataEditor}
                        onChange={(value) => {
                          setDataEditor(value);
                        }}
                      />
                    </div>
                    <div className="d-flex flex-column gap-2">
                      <label className="">
                        <span className="d-flex flex-row align-items-center">
                          Imagem:
                        </span>
                        {!uploadImage ? (
                          imagePreviews?.map((item, index) => (
                            <div className="ImagemPreview " key={index}>
                              <MdCancel
                                className="IconImagem"
                                type="button"
                                style={{ color: "red" }}
                                onClick={() => RemoveImage(index)}
                              />
                              <img
                                key={index}
                                src={item.Url ? item.Url : item}
                                alt={`Preview ${index + 1}`}
                                style={{}}
                              />
                            </div>
                          ))
                        ) : (
                          <Box
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            <CircularProgress />
                          </Box>
                        )}
                      </label>
                      <div className=" mt-2 h-50 d-flex  ">
                        <Button
                          className=""
                          onClick={handleButtonImageClick}
                          disabled={uploadImage}
                        >
                          Upload de arquivo
                        </Button>

                        <input
                          className="position-absolute"
                          type="file"
                          ref={inputImageRef}
                          hidden
                          onChange={handleImagensChange}
                        />
                      </div>
                    </div>
                    <h3 className="mt-3">Especificações Complementares</h3>
                    <div className="">
                      <table className="table">
                        <tbody>
                          <tr className="">
                            <th>Número</th>
                            <th className="text-center">Nome</th>
                            <th className="text-center">Tamanho do Arquivo</th>
                            <th></th>
                          </tr>
                          {!uploadFile ? (
                            selectedFiles?.map((file, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td className="text-center">{file.name}</td>
                                <td className="text-center">
                                  {FormatBytes(file.size)}
                                </td>
                                <td>
                                  <div>
                                    <MdCancel
                                      className="IconImagem"
                                      type="button"
                                      style={{ color: "red" }}
                                      onClick={() => RemoveItem(index)}
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <Box
                              sx={{ display: "flex", justifyContent: "center" }}
                            >
                              <CircularProgress />
                            </Box>
                          )}
                        </tbody>
                      </table>
                      <div className=" mt-2 h-50 d-flex  ">
                        <Button
                          className=""
                          onClick={handleButtonClick}
                          disabled={uploadFile}
                        >
                          Selecione o arquivo
                        </Button>

                        <input
                          className="position-absolute"
                          type="file"
                          ref={inputRef}
                          hidden
                          onChange={handleFileChange}
                        />
                      </div>
                    </div>
                    <h3 className="mt-5">Preços:</h3>
                    <div className="formAddMaterialHorizontal ">
                      <div className="formAddMaterialVertical">
                        <FormGroup className="mb-3 p-1 formGroup  w-75">
                          <label className="">
                            <span className="d-flex flex-row align-items-center">
                              Valor unitário:
                            </span>
                          </label>
                          <Field
                            type="number"
                            className="form-control"
                            name="valorUnitario"
                          />
                          <ErrorMessage component="p" name="valorUnitario" />
                        </FormGroup>
                        <FormGroup className="mb-3 p-1 formGroup w-75">
                          <label className="">
                            <span className="d-flex flex-row align-items-center">
                              Frequência do Preço
                            </span>
                          </label>
                          <Select
                            className="inputSelect"
                            name="frequenciaSelect"
                            options={OptionsFrequencia}
                            placeholder="Selecione"
                            value={values.frequenciaSelect}
                            onChange={(selectedOptions) => {
                              setFieldValue(
                                "frequenciaSelect",
                                selectedOptions
                              );
                            }}
                          />
                          <ErrorMessage component="p" name="frequenciaSelect" />
                        </FormGroup>
                      </div>
                      <div className="formAddMaterialVertical">
                        <FormGroup className="mb-3 p-1 formGroup  w-75">
                          <label className="">
                            <span className="d-flex flex-row align-items-center">
                              Data inicial do Preço
                            </span>
                          </label>
                          <DatePicker
                            name="dataInicial"
                            format="DD/MM/YYYY"
                            className="datePiker w-100"
                            label="Data Inicial"
                            value={values.dataInicial}
                            onChange={(value) => {
                              setFieldValue("dataInicial", value);
                            }}
                          />
                          <ErrorMessage component="p" name="dataInicial" />
                        </FormGroup>
                        <FormGroup className="mb-3 p-1 formGroup w-75">
                          <label className="">
                            <span className="d-flex flex-row align-items-center">
                              Validade do Preço
                            </span>
                          </label>
                          <DatePicker
                            name="validade"
                            format="DD/MM/YYYY"
                            className="datePiker w-100"
                            label="Data Inicial"
                            value={values.validade}
                            onChange={(value) => {
                              setFieldValue("validade", value);
                            }}
                          />
                          <ErrorMessage component="p" name="validade" />
                        </FormGroup>
                      </div>
                    </div>
                    <div className="d-flex flex-row gap-2">
                      <Field
                        type="checkbox"
                        name="descontinuadoCheck"
                        checked={values.descontinuadoCheck}
                        onChange={(e) => {
                          const { name, checked } = e.target;
                          setFieldValue(name, checked);
                        }}
                      />
                      <label className="">
                        <span className="d-flex flex-row align-items-center">
                          Descontinuado
                        </span>
                      </label>
                    </div>
                    <div className="d-flex flex-row gap-2">
                      <Field
                        type="checkbox"
                        name="registroCheck"
                        checked={values.registroCheck}
                        onChange={(e) => {
                          const { name, checked } = e.target;
                          setFieldValue(name, checked);
                        }}
                      />
                      <label className="">
                        <span className="d-flex flex-row align-items-center">
                          Registro de Preço
                        </span>
                      </label>
                    </div>
                    <div className="d-flex flex-row gap-2">
                      <Field
                        type="checkbox"
                        name="EspecificoCheck"
                        checked={values.EspecificoCheck}
                        onChange={(e) => {
                          const { name, checked } = e.target;
                          setFieldValue(name, checked);
                        }}
                      />
                      <label className="">
                        <span className="d-flex flex-row align-items-center">
                          Material Específico
                        </span>
                      </label>
                    </div>
                  </div>
                  {NavigateButtons()}
                </Form>
              )}
            </Formik>
          </main>
          <Footer />
        </>
      ) : (
        <></>
      )}
    </>
  );
}
