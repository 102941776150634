import styles from "./ListarControleEspecialMaterial.scss";

import axios from "axios";
import { Field, Form, Formik } from "formik";
import * as jose from "jose";
import { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { HiPencil } from "react-icons/hi";
import { MdCancel } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Footer from "../../../../components/Footer/Footer";
import { ModalError } from "../../../../components/ModalError/ModalError";
import Navbar from "../../../../components/Navbar/Navbar";
import AuthContext from "../../../../contexts/auth";
import { DataUserContext } from "../../../../contexts/context";
export function ListarControleEspecialMaterial() {
  const MySwal = withReactContent(Swal);
  const [TokenDecode, setTokenDecode] = useState("");
  const { userData } = useContext(DataUserContext);
  const [erroPage, setErrorPage] = useState(true);

  const [ControleEspecial, setControleEspecial] = useState([]);
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AuthContext);
  const options = [
    { label: "Sim", value: 1 },
    { label: "Não", value: 2 },
  ];
  useEffect(() => {
    setTokenDecode(decodeJWT(state.token));
  }, [state.token]);
  function decodeJWT(token) {
    try {
      const decoded = jose.decodeJwt(token);
      return decoded;
    } catch (error) {
      return null;
    }
  }

  async function handleClick() {
    navigate("/Materiais/Novo-Controle-Especial");
  }

  async function handleFilter(event) {
    try {
      await axios
        .get(
          `${process.env.REACT_APP_BASEURL}/api/v1.0/materiais/controles-especiais?nome=${event.nome}`,
          {
            headers: {
              unidade_compradora: 227,
              Authorization: `Bearer ${state.token}`,
            },
          }
        )
        .then((res) => {
          if (res.data) {
            setControleEspecial(res?.data);
          } else {
            setControleEspecial([]);
          }
        });
    } catch (error) {
      ModalError(error);
    }
  }

  function RemoveItem(index) {
    MySwal.fire({
      title: "Tem certeza?",
      text: "Você não será capaz de reverter isso!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Não",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, exclua!",
    }).then((result) => {
      if (result.isConfirmed) {
        const items = [...ControleEspecial];

        removeFileApi(items[index].Codigo);
        items.splice(index, 1);

        //limpa input para receber o mesmo arquivo
        setControleEspecial(items);

        MySwal.fire("Excluído!", "O Item foi deletado.", "success");
      }
    });
  }

  function removeFileApi(code) {
    try {
      axios
        .delete(
          `${process.env.REACT_APP_BASEURL}/api/v1.0/materiais/controles-especiais/${code}`,
          {
            headers: {
              unidade_compradora: 227,
              Authorization: `Bearer ${state.token}`,
            },
          }
        )
        .then((res) => {});
    } catch (error) {
      ModalError(error);
    }
  }

  async function getData() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/materiais/controles-especiais`,
        {
          headers: {
            unidade_compradora: 227,
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      if (response.data) {
        const filtro = response.data.filter(
          (item) => item.Status !== "Deletado"
        );
        setControleEspecial(filtro);
      }
      // setErrorPage(true);
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: `Desculpe, ocorreu um erro ao consumir o serviço. Contate nosso suporte (ERROR ${error?.response?.status}).`,

        backdrop: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }

  useEffect(() => {
    getData();
  }, []);
  async function handleEditCalendar(Codigo) {
    navigate(`/Materiais/Novo-Controle-Especial?Code=${Codigo}`);
  }
  return (
    <>
      {erroPage ? (
        <>
          <Navbar
            link={"/Materiais"}
            NomeLink="Catálogo de Materiais"
            titulo="Controles Especiais - Materiais"
            button={{ text: "+ Cadastrar nova" }}
            onClick={handleClick}
          />

          <main className="listagem-Material">
            <Formik
              initialValues={{
                nome: "",
              }}
              onSubmit={handleFilter}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <div className="container pt-3 d-flex  justify-content-center   ">
                    <div className="formClasses w-50  ">
                      <div className="w-50">
                        <Field
                          type="text"
                          name="nome"
                          placeholder="Nome"
                          className="form-control inputForm "
                        />
                      </div>

                      <div className="">
                        <button className="btn btn-primary " type="submit">
                          Pesquisar
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
            <div className="container">
              <table className="table">
                <tbody>
                  <tr className={styles.linha__tabbel_listagem}>
                    <th>Nome</th>
                    <th>Ações</th>
                  </tr>

                  {ControleEspecial?.map((item, index) => (
                    <tr key={item?.Codigo}>
                      <td>{item.Nome}</td>

                      <td>
                        <div>
                          <HiPencil
                            type="button"
                            name="teste"
                            onClick={() => handleEditCalendar(item?.Codigo)}
                          />
                          <MdCancel
                            type="button"
                            style={{ color: "red" }}
                            onClick={() => RemoveItem(index)}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="d-flex flex-row-reverse">
              <Button onClick={() => navigate("/Materiais")}>voltar</Button>
            </div>
          </main>
          <Footer />
        </>
      ) : (
        <></>
      )}
    </>
  );
}
