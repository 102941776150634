import axios from "axios";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import AuthContext from "../../contexts/auth";
import { FormatBytes } from "../../functions/FormatBytes";
import { removerTags } from "../../functions/RemoveHtml/RemoveHTML";
import "./ModalDetailSolicitaçao.scss";
export function ModalDetailSolicitaçao({
  showModal,
  handleCloseModal,
  itemId,
}) {
  const initialValues = {
    selecao: "",
  };
  const { state, dispatch } = useContext(AuthContext);
  const MySwal = withReactContent(Swal);
  const [ItemData, setItemData] = useState();

  useEffect(() => {
    console.log(itemId);
    if (itemId !== undefined) {
      GetObjetos();
    }
  }, [itemId]);

  async function GetObjetos() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/materiais/solicitacoes/${itemId}`,

        {
          headers: {
            unidade_compradora: 227,
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      if (response?.data) {
        setItemData(response?.data);
      }
    } catch (error) {
      console.error(error);
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: `Desculpe, ocorreu um erro ao consumir o serviço. Contate nosso suporte (ERROR ${error?.response?.status}).`,

        backdrop: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }

  function ChangerModal(option) {
    handleCloseModal();
  }
  return (
    <Modal
      dialogClassName="modal-100w"
      show={showModal}
      size="xl"
      onHide={handleCloseModal}
      animation={false}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton className="ModalTitle">
        <Modal.Title className="text-light">Detalhes do Material</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="w-100 ">
          <div className="row w-100">
            <div className="">
              <div>
                <div className="rowContainerModal">
                  <div className="textContainer">
                    <div>
                      <p>Nome:</p>
                      {ItemData?.Material?.Nome}
                    </div>

                    <div>
                      <p> Classe:</p>
                      {ItemData?.Material?.Classe?.Nome}
                    </div>

                    <div>
                      <p> Periculosidade:</p>
                      {ItemData?.Material?.Periculosidade}
                    </div>

                    <div>
                      <p> Unidade de Medida </p>
                      {ItemData?.Material?.UnidadeMedida?.Sigla}
                    </div>
                    <div>
                      <p> Frequência: </p>
                      {ItemData?.Material?.Preco?.Frequencia}
                    </div>
                    {ItemData?.Material?.Preco?.DataInicial && (
                      <div>
                        <p> Data de inicio: </p>

                        {dayjs(ItemData?.Material?.Preco?.DataInicial).format(
                          "DD/MM/YYYY"
                        )}
                      </div>
                    )}
                    <div>
                      <p> Registro: </p>
                      {ItemData?.Material?.Preco?.Registro ? "sim" : "não"}
                    </div>
                    <div>
                      <p> Descrição: </p>
                      {removerTags(ItemData?.Material?.Descricao || "")}
                    </div>
                    {ItemData?.JustificativaReprovacao && (
                      <div>
                        <p className="text-danger">
                          {" "}
                          Justificativa da Reprovação:{" "}
                        </p>
                        {removerTags(ItemData?.JustificativaReprovacao)}
                      </div>
                    )}
                  </div>
                  <div className="textContainer">
                    <div>
                      <p>Tipo de Material:</p>
                      {ItemData?.Material?.TipoMaterial}
                    </div>
                    <div>
                      <p> Subclasse: </p>
                      {ItemData?.Material?.Subclasse?.Nome}
                    </div>
                    <div>
                      <p> Controle Especial: </p>
                      {ItemData?.Material?.ControleEspecial?.Nome}
                    </div>
                    {ItemData?.Material?.Preco?.ValorUnitario && (
                      <div>
                        <p> Valor Unitário: </p>
                        R$ {ItemData?.Material?.Preco?.ValorUnitario}
                      </div>
                    )}
                    <div>
                      <p> Descontinuado: </p>
                      {ItemData?.Material?.Preco?.Descontinuado ? "sim" : "não"}
                    </div>
                    {ItemData?.Material?.Preco?.Validade && (
                      <div>
                        <p> Validade: </p>

                        {dayjs(ItemData?.Material?.Preco?.Validade).format(
                          "DD/MM/YYYY"
                        )}
                      </div>
                    )}
                    <div>
                      <p> Material Especifico: </p>
                      {ItemData?.Preco?.Material?.MaterialEspecifico
                        ? "sim"
                        : "não"}
                    </div>
                  </div>
                </div>
              </div>
              <h5 className="mt-2">Imagens:</h5>
              {ItemData?.Material?.Imagens?.map((item, index) => (
                <div className="ImagemPreview " key={index}>
                  <img
                    key={index}
                    src={item.Url ? item.Url : item}
                    alt={`Preview ${index + 1}`}
                    style={{}}
                  />
                </div>
              ))}
              <h5 className="mt-2">Especificações Complementares:</h5>
              <table className="table">
                <tbody>
                  <tr className="">
                    <th>Número</th>
                    <th className="text-center">Nome</th>
                    <th className="text-center">Tamanho do Arquivo</th>
                  </tr>
                  {ItemData?.Material?.EspecificacoesComplementares?.map(
                    (file, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td className="text-center">{file.Nome}</td>
                        <td className="text-center">
                          {FormatBytes(file.Tamanho)}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
