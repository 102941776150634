import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as jose from "jose";
import { useContext, useEffect, useState } from "react";
import { Button, FormGroup } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Footer from "../../../components/Footer/Footer";
import Navbar from "../../../components/Navbar/Navbar";
import AuthContext from "../../../contexts/auth";
import { DataUserContext } from "../../../contexts/context";

import Select from "react-select";
import * as yup from "yup";
import { ModalErrorMessage } from "../../../components/ModalErrorMessage /ModalErrorMessage ";
import { ModalSuccess } from "../../../components/ModalSuccess/ModalSuccess";
import { IsMaterial } from "../../../functions/IsMaterial";

export function AddSubClasse() {
  const MySwal = withReactContent(Swal);
  const [TokenDecode, setTokenDecode] = useState("");
  const { userData } = useContext(DataUserContext);
  const [erroPage, setErrorPage] = useState(true);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [CalendarData, setCalendarData] = useState([]);
  const [anoStart, setAnoStart] = useState();
  const [inicioPrevisoes, setinicioPrevisoes] = useState();
  const [fimPrevisoes, setfimPrevisoes] = useState();
  const [Load, setLoad] = useState(false);
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AuthContext);
  const [NewInpult, setNewInpult] = useState(true);
  const [Code, setCode] = useState("");
  const [OptionsClasses, setOptionsClasses] = useState([]);
  const [SubClasseData, setSubClasseData] = useState([]);

  useEffect(() => {
    setTokenDecode(decodeJWT(state.token));
  }, [state.token]);
  function decodeJWT(token) {
    try {
      const decoded = jose.decodeJwt(token);
      return decoded;
    } catch (error) {
      return null;
    }
  }
  const schema = yup.object().shape({
    codigoSubClasse: yup
      .string()
      .required("O codigo da SubClasseclasse e obrigatório")
      .typeError("O codigo da classe e obrigatório"),
    NomeSubClasse: yup
      .string()
      .required("O nome da classe e obrigatório")
      .typeError("O nome da classe e obrigatório"),
    classeSelect: yup
      .object()
      .required("A classe e obrigatória")
      .typeError("A classe e obrigatória"),
  });
  async function handleSubmit(event) {
    let TypeService = IsMaterial() ? "materiais" : "servicos";
    if (NewInpult) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASEURL}/api/v1.0/${TypeService}/subclasses`,
          {
            CodigoSubclasse: event.codigoSubClasse,
            Nome: event.NomeSubClasse,
            CodigoClasse: event.classeSelect.value,
          },
          {
            headers: {
              unidade_compradora: 227,
              Authorization: `Bearer ${state.token}`,
            },
          }
        );
        ModalSuccess("A Subclasse foi adicionada");

        ActionRetornarButton();
      } catch (error) {
        ModalErrorMessage(error);
      }
    } else {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_BASEURL}/api/v1.0/${TypeService}/subclasses`,
          {
            Codigo: Code,
            CodigoSubclasse: event.codigoSubClasse,
            Nome: event.NomeSubClasse,
            CodigoClasse: event.classeSelect.value,
          },
          {
            headers: {
              unidade_compradora: 227,
              Authorization: `Bearer ${state.token}`,
            },
          }
        );
        ModalSuccess("A Subclasse foi Atualizada");

        ActionRetornarButton();
        // setErrorPage(true);
      } catch (error) {
        console.log(error);

        ModalErrorMessage(error);
      }
    }
  }
  async function getDataClasses() {
    let TypeService = IsMaterial() ? "materiais" : "servicos";
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/${TypeService}/classes`,
        {
          headers: {
            unidade_compradora: 227,
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      if (response.data) {
        const filtro = response.data.filter(
          (item) => item.Status !== "Deletado"
        );
        let Options = filtro.map((item) => {
          return { label: item.Nome, value: item.Codigo };
        });
        setOptionsClasses(Options);
      }
      // setErrorPage(true);
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: `Desculpe, ocorreu um erro ao consumir o serviço. Contate nosso suporte (ERROR ${error?.response?.status}).`,

        backdrop: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }

  async function getData(Codigo) {
    let TypeService = IsMaterial() ? "materiais" : "servicos";
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/${TypeService}/subclasses/${Codigo}`,
        {
          headers: {
            unidade_compradora: 227,
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      if (response.data) {
        setSubClasseData(response.data);
      }
      setNewInpult(false);
      setLoad(true);
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: `Desculpe, ocorreu um erro ao consumir o serviço. Contate nosso suporte (ERROR ${error?.response?.status}).`,

        backdrop: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }

  useEffect(() => {
    const code = searchParams.get("Code");
    if (code !== null) {
      getDataClasses();
      getData(code);
      setCode(code);
    } else {
      setLoad(true);
      getDataClasses();
    }
  }, []);

  function ActionRetornarButton() {
    if (IsMaterial()) {
      navigate(`/Materiais/SubClasses`);
    } else {
      navigate(`/Servicos/SubClasses`);
    }
  }

  return (
    <>
      {Load ? (
        <>
          <Navbar
            // subtitulo="Módulo de Planejamento"
            NomeLink={
              IsMaterial() ? "Catálogo de Materiais" : "Catálogo de Serviços"
            }
            link={IsMaterial() ? "/Materiais" : "/Servicos"}
            titulo={
              IsMaterial()
                ? "Cadastrar SubClasse - Materiais"
                : "Cadastrar SubClasse - Serviços"
            }
          />

          <main className="listagem-Calendario-PCA">
            <h3 className="mt-3"> Cadastrar subclasse</h3>
            <Formik
              validationSchema={schema}
              validateOnBlur={false}
              initialValues={{
                codigoSubClasse: SubClasseData.CodigoSubclasse
                  ? SubClasseData.CodigoSubclasse
                  : "",
                NomeSubClasse: SubClasseData.Nome ? SubClasseData.Nome : "",
                classeSelect: SubClasseData.Classe
                  ? {
                      label: SubClasseData.Classe.Nome,
                      value: SubClasseData.Classe.Codigo,
                    }
                  : "",
              }}
              onSubmit={handleSubmit}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <div className="container pt-3 ">
                    <div className="formAdd ">
                      <FormGroup className="mb-3 p-1 formGroup w-25">
                        <label className="">
                          <span className="d-flex flex-row align-items-center">
                            Código da Subclasse
                          </span>
                        </label>
                        <Field
                          type="text"
                          className="form-control"
                          name="codigoSubClasse"
                        />
                        <ErrorMessage component="p" name="codigoSubClasse" />
                      </FormGroup>
                      <FormGroup className="mb-3 p-1 formGroup w-25">
                        <label className="">
                          <span className="d-flex flex-row align-items-center">
                            Nome da Subclasse:
                          </span>
                        </label>
                        <Field
                          type="text"
                          className="form-control"
                          name="NomeSubClasse"
                        />
                        <ErrorMessage component="p" name="NomeSubClasse" />
                      </FormGroup>
                    </div>
                    <div className="formAdd w-50">
                      <FormGroup className="mt-3 p-1 formGroup w-50 ">
                        <label className="">
                          <span className="d-flex flex-row align-items-center">
                            Classe correspondente
                          </span>
                        </label>

                        <Select
                          className="w-100"
                          name="classeSelect"
                          value={values.classeSelect}
                          placeholder="Selecione"
                          options={OptionsClasses}
                          onChange={(selectedOptions) => {
                            setFieldValue("classeSelect", selectedOptions);
                          }}
                        />

                        <ErrorMessage component="p" name="classeSelect" />
                      </FormGroup>
                    </div>
                  </div>
                  <div className="d-flex flex-row-reverse gap-3 mt-5">
                    <Button type="submit">Salvar</Button>
                    <Button onClick={() => ActionRetornarButton()}>
                      Cancelar
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </main>
          <Footer />
        </>
      ) : (
        <></>
      )}
    </>
  );
}
