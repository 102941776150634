import { ReactSession } from "react-client-session";
import { Decode } from "../services/Decode";
function authReducer(state, action) {
  let userRole = Decode(action.payload);
  console.log(userRole);
  switch (action.type) {
    case "LOGIN":
      ReactSession.set("token", action.payload);

      return {
        ...state,
        isAuthenticated: true,
        token: action.payload,
        isAdmin: userRole.role.includes("administrador"),
        decodeToken: userRole,
      };
    case "LOGOUT":
      ReactSession.remove("token");
      return {
        ...state,
        isAuthenticated: false,
      };
    case "LOADED":
      return {
        isAuthenticated: true,
        token: action.payload,
        isAdmin: userRole.role.includes("administrador"),
        decodeToken: userRole,
      };
    default:
      return state;
  }
}

export const authReducerInitialValue = () => {
  const Token = ReactSession.get("token");

  if (Token) {
    return {
      isAuthenticated: true,
      token: Token,
      isAdmin: false,
      decodeToken: [],
    };
  } else {
    return {
      isAuthenticated: false,
      token: null,
      isAdmin: false,
      decodeToken: [],
    };
  }
};
export default authReducer;
