import { useContext, useEffect, useState } from "react";
import Footer from "../../../components/Footer/Footer";
import Navbar from "../../../components/Navbar/Navbar";

import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as jose from "jose";
import { Button, FormGroup } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { DataUserContext } from "../../../contexts/context";

import * as yup from "yup";

import { ModalError } from "../../../components/ModalError/ModalError";
import { ModalSuccess } from "../../../components/ModalSuccess/ModalSuccess";
import AuthContext from "../../../contexts/auth";
export function AddClasse() {
  const MySwal = withReactContent(Swal);
  const [TokenDecode, setTokenDecode] = useState("");
  const { userData } = useContext(DataUserContext);
  const [erroPage, setErrorPage] = useState(true);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [CalendarData, setCalendarData] = useState([]);
  const [anoStart, setAnoStart] = useState();
  const [inicioPrevisoes, setinicioPrevisoes] = useState();
  const [ClasseData, setClasseData] = useState();
  const [Load, setLoad] = useState(false);
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AuthContext);
  const [NewInpult, setNewInpult] = useState(true);
  const [Code, setCode] = useState("");

  function IsMaterial() {
    const caminhoAtual = window.location.pathname;

    if (caminhoAtual.includes("/Materiais")) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    setTokenDecode(decodeJWT(state.token));
  }, [state.token]);
  function decodeJWT(token) {
    try {
      const decoded = jose.decodeJwt(token);
      return decoded;
    } catch (error) {
      return null;
    }
  }
  const schema = yup.object().shape({
    codigoClasse: yup
      .string()
      .required("O Nome é obrigatório")
      .typeError("O Nome  é obrigatório"),
    NomeClasse: yup
      .string()
      .required("O Nome é obrigatório")
      .typeError("O Nome é obrigatório"),
  });
  async function handleSubmit(event) {
    let TypeService = IsMaterial() ? "materiais" : "servicos";
    if (NewInpult) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASEURL}/api/v1.0/${TypeService}/classes`,
          {
            CodigoClasse: event.codigoClasse,
            Nome: event.NomeClasse,
            TecnologiaInformacaoComunicacao: event.checkedTIC,
            ServicoEngenharia: event.checkedEngenharia,
          },
          {
            headers: {
              unidade_compradora: 227,
              Authorization: `Bearer ${state.token}`,
            },
          }
        );
        ModalSuccess("A Classe foi adicionada");

        // setErrorPage(true);
        ActionRetornarButton();
      } catch (error) {
        ModalError(error);
      }
    } else {
      try {
        let TypeService = IsMaterial() ? "materiais" : "servicos";
        const response = await axios.put(
          `${process.env.REACT_APP_BASEURL}/api/v1.0/${TypeService}/classes`,
          {
            Codigo: Code,
            CodigoClasse: event.codigoClasse,
            Nome: event.NomeClasse,
            TecnologiaInformacaoComunicacao: event.checkedTIC,
            ServicoEngenharia: event.checkedEngenharia,
          },
          {
            headers: {
              unidade_compradora: 227,
              Authorization: `Bearer ${state.token}`,
            },
          }
        );
        ModalSuccess("A Classe foi atualizada");
        ActionRetornarButton();
        // setErrorPage(true);
      } catch (error) {
        console.log(error);

        ModalError(error);
      }
    }
  }

  async function getData(Codigo) {
    let TypeService = IsMaterial() ? "materiais" : "servicos";
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/${TypeService}/classes/${Codigo}`,
        {
          headers: {
            unidade_compradora: 227,
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      if (response.data) {
        setClasseData(response.data);
      }
      // setErrorPage(true);
      setNewInpult(false);
      setLoad(true);
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: `Desculpe, ocorreu um erro ao consumir o serviço. Contate nosso suporte (ERROR ${error?.response?.status}).`,

        backdrop: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }

  useEffect(() => {
    const code = searchParams.get("Code");
    if (code !== null) {
      getData(code);
      setCode(code);
    } else {
      setLoad(true);
    }
  }, []);

  useEffect(() => {}, [inicioPrevisoes]);

  function ActionRetornarButton() {
    if (IsMaterial()) {
      navigate(`/Materiais/Classes`);
    } else {
      navigate(`/Servicos/Classes`);
    }
  }

  return (
    <>
      {Load ? (
        <>
          <Navbar
            // subtitulo="Módulo de Planejamento"
            NomeLink={
              IsMaterial() ? "Catálogo de Materiais" : "Catálogo de Serviços"
            }
            link={IsMaterial() ? "/Materiais" : "/Servicos"}
            titulo={
              IsMaterial()
                ? "Cadastrar Classe - Materiais"
                : "Cadastrar Classe - Serviços"
            }
          />

          <main className="listagem-Calendario-PCA">
            <h3 className="mt-3"> Cadastrar Classe</h3>
            <Formik
              validationSchema={schema}
              validateOnBlur={false}
              initialValues={{
                codigoClasse: ClasseData?.CodigoClasse
                  ? ClasseData?.CodigoClasse
                  : "",
                NomeClasse: ClasseData?.Nome ? ClasseData?.Nome : "",
                checkedTIC: ClasseData?.TecnologiaInformacaoComunicacao
                  ? ClasseData?.TecnologiaInformacaoComunicacao
                  : false,
                checkedEngenharia: ClasseData?.ServicoEngenharia
                  ? ClasseData?.ServicoEngenharia
                  : false,
              }}
              onSubmit={handleSubmit}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <div className="container pt-3 ">
                    <div className="formAdd  gap-5">
                      <FormGroup className="mb-3 p-1 formGroup w-50">
                        <label className="">
                          <span className="d-flex flex-row align-items-center">
                            Código da Classe
                          </span>
                        </label>
                        <Field
                          type="text"
                          className="form-control"
                          name="codigoClasse"
                        />
                        <ErrorMessage component="p" name="codigoClasse" />
                      </FormGroup>
                      <FormGroup className="mb-3 p-1 formGroup w-50 align-content-end">
                        <label className="">
                          <span className="d-flex flex-row align-items-center">
                            Nome da classe
                          </span>
                        </label>
                        <Field
                          type="text"
                          className="form-control"
                          name="NomeClasse"
                        />
                        <ErrorMessage component="p" name="NomeClasse" />
                      </FormGroup>
                    </div>
                    <div className="d-flex flex-row gap-2">
                      <Field
                        type="checkbox"
                        name="checkedTIC"
                        checked={values.checkedTIC}
                        onChange={(e) => {
                          const { name, checked } = e.target;
                          setFieldValue(name, checked);
                        }}
                      />
                      <label className="">
                        <span className="d-flex flex-row align-items-center">
                          Tecnologia da Informação e Comunicação (TIC)
                        </span>
                      </label>
                    </div>
                    {!IsMaterial() && (
                      <div className="d-flex flex-row gap-2">
                        <Field
                          type="checkbox"
                          name="checkedEngenharia"
                          checked={values.checkedEngenharia}
                          onChange={(e) => {
                            const { name, checked } = e.target;
                            setFieldValue(name, checked);
                          }}
                        />
                        <label className="">
                          <span className="d-flex flex-row align-items-center">
                            Serviço de Engenharia
                          </span>
                        </label>
                      </div>
                    )}
                  </div>
                  <div className="d-flex flex-row-reverse gap-3 mt-5">
                    <Button type="submit">Salvar</Button>
                    <Button onClick={() => ActionRetornarButton()}>
                      Cancelar
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </main>
          <Footer />
        </>
      ) : (
        <></>
      )}
    </>
  );
}
