import styles from "./ListaSubClasses.scss";

import axios from "axios";
import { Field, Form, Formik } from "formik";
import * as jose from "jose";
import { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { HiPencil } from "react-icons/hi";
import { MdCancel } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import addClassseSVG from "../../../assets/icons/note-plus-outline 1.svg";
import Footer from "../../../components/Footer/Footer";
import { ModalError } from "../../../components/ModalError/ModalError";
import Navbar from "../../../components/Navbar/Navbar";
import AuthContext from "../../../contexts/auth";
import { DataUserContext } from "../../../contexts/context";
import { IsMaterial } from "../../../functions/IsMaterial";
export function ListaSubClasses() {
  const MySwal = withReactContent(Swal);
  const [TokenDecode, setTokenDecode] = useState("");
  const { userData } = useContext(DataUserContext);
  const [erroPage, setErrorPage] = useState(true);
  const [SubClasseData, setSubClasseData] = useState([]);
  const [OptionsClasses, setOptionsClasses] = useState([]);
  const [fimPrevisoes, setfimPrevisoes] = useState();
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AuthContext);

  useEffect(() => {
    setTokenDecode(decodeJWT(state.token));
  }, [state.token]);
  function decodeJWT(token) {
    try {
      const decoded = jose.decodeJwt(token);
      return decoded;
    } catch (error) {
      return null;
    }
  }

  async function handleFilter(event) {
    try {
      await axios
        .get(
          `${process.env.REACT_APP_BASEURL}/api/v1.0/materiais/subclasses?nome=${event.nome}&codigoClasse=${event.classeSelect.value}`,
          {
            headers: {
              unidade_compradora: 227,
              Authorization: `Bearer ${state.token}`,
            },
          }
        )
        .then((res) => {
          if (res.data) {
            setSubClasseData(res?.data);
          } else {
            setSubClasseData([]);
          }
        });
    } catch (error) {
      ModalError(error);
    }
  }

  function RemoveItem(index) {
    MySwal.fire({
      title: "Tem certeza?",
      text: "Você não será capaz de reverter isso!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Não",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, exclua!",
    }).then((result) => {
      if (result.isConfirmed) {
        const items = [...SubClasseData];

        removeFileApi(items[index].Codigo);
        items.splice(index, 1);

        //limpa input para receber o mesmo arquivo
        setSubClasseData(items);

        MySwal.fire("Excluído!", "O Item foi deletado.", "success");
      }
    });
  }

  function removeFileApi(code) {
    try {
      axios
        .delete(
          `${process.env.REACT_APP_BASEURL}/api/v1.0/materiais/subclasses/${code}`,
          {
            headers: {
              unidade_compradora: 227,
              Authorization: `Bearer ${state.token}`,
            },
          }
        )
        .then((res) => {});
    } catch (error) {
      ModalError(error);
    }
  }

  async function handleClick() {
    if (IsMaterial()) {
      navigate("/Materiais/Nova-SubClasse");
    } else {
      navigate("/Servicos/Nova-SubClasse");
    }
  }
  async function getDataClasses() {
    let TypeService = IsMaterial() ? "materiais" : "servicos";
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/${TypeService}/classes`,
        {
          headers: {
            unidade_compradora: 227,
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      if (response.data) {
        const filtro = response.data.filter(
          (item) => item.Status !== "Deletado"
        );
        let Options = filtro.map((item) => {
          return { label: item.Nome, value: item.Codigo };
        });
        setOptionsClasses(Options);
      }
      // setErrorPage(true);
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: `Desculpe, ocorreu um erro ao consumir o serviço. Contate nosso suporte (ERROR ${error?.response?.status}).`,

        backdrop: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }
  async function getData() {
    let TypeService = IsMaterial() ? "materiais" : "servicos";
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/${TypeService}/subclasses`,
        {
          headers: {
            unidade_compradora: 227,
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      if (response.data) {
        const filtro = response.data.filter(
          (item) => item.Status !== "Deletado"
        );
        setSubClasseData(filtro);
      }
      // setErrorPage(true);
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: `Desculpe, ocorreu um erro ao consumir o serviço. Contate nosso suporte (ERROR ${error?.response?.status}).`,

        backdrop: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }

  useEffect(() => {
    getData();
    getDataClasses();
  }, []);

  async function handleEdit(Codigo) {
    if (IsMaterial()) {
      navigate(`/Materiais/Nova-SubClasse?Code=${Codigo}`);
    } else {
      navigate(`/Servicos/Nova-SubClasse?Code=${Codigo}`);
    }
  }

  function ActionRetornarButton() {
    if (IsMaterial()) {
      navigate(`/Materiais`);
    } else {
      navigate(`/Servicos`);
    }
  }

  return (
    <>
      {erroPage ? (
        <>
          <Navbar
            link={IsMaterial() ? "/Materiais" : "/Servicos"}
            NomeLink={
              IsMaterial() ? "Catálogo de Materiais" : "Catálogo de Serviços"
            }
            titulo={
              IsMaterial() ? "Subclasse - Materiais" : "Subclasse - Serviços"
            }
            button={{ text: "+ Cadastrar nova" }}
            onClick={handleClick}
          />
          <div className=" container iconsHeader">
            <div
              onClick={() =>
                navigate(
                  IsMaterial() ? "/Materiais/Classes" : "/Servicos/Classes"
                )
              }
            >
              <img src={addClassseSVG} alt="Logo do +Classe" />
              <span>Classe</span>
            </div>
          </div>
          <main className="listagem-Material">
            <Formik
              initialValues={{
                nome: "",
                classeSelect: "",
              }}
              onSubmit={handleFilter}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <div className="container pt-3 d-flex  justify-content-center   ">
                    <div className="formClasses w-100  ">
                      <div className="w-25">
                        <Field
                          type="text"
                          name="nome"
                          placeholder="Nome"
                          className="form-control inputForm "
                        />
                      </div>
                      <div className="w-25">
                        <Select
                          className="w-100"
                          name="classeSelect"
                          value={values.classeSelect}
                          placeholder="Classes"
                          options={OptionsClasses}
                          onChange={(selectedOptions) => {
                            setFieldValue("classeSelect", selectedOptions);
                          }}
                        />
                      </div>

                      <div className="">
                        <button className="btn btn-primary " type="submit">
                          Pesquisar
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
            <div className="container">
              <table className="table">
                <tbody>
                  <tr className={styles.linha__tabbel_listagem}>
                    <th>Nome</th>
                    <th>Classe</th>

                    <th>Ações</th>
                  </tr>

                  {SubClasseData?.map((item, index) => (
                    <tr key={item?.Codigo}>
                      <td>{item.Nome}</td>
                      <td>{item.Classe?.Nome}</td>

                      <td>
                        <div>
                          <HiPencil
                            type="button"
                            name="teste"
                            onClick={() => handleEdit(item?.Codigo)}
                          />
                          <MdCancel
                            type="button"
                            style={{ color: "red" }}
                            onClick={() => RemoveItem(index)}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="d-flex flex-row-reverse">
              <Button onClick={() => ActionRetornarButton()}>voltar</Button>
            </div>
          </main>
          <Footer />
        </>
      ) : (
        <></>
      )}
    </>
  );
}
