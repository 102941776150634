import { useContext, useEffect, useState } from "react";
import Footer from "../../../components/Footer/Footer";
import Navbar from "../../../components/Navbar/Navbar";

import axios from "axios";
import { Field, Form, Formik } from "formik";
import * as jose from "jose";
import { Button } from "react-bootstrap";
import { HiPencil } from "react-icons/hi";
import { MdCancel } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { ModalError } from "../../../components/ModalError/ModalError";
import AuthContext from "../../../contexts/auth";
import { DataUserContext } from "../../../contexts/context";

import { FaEye } from "react-icons/fa";
import { ModalDetailSolicitaçaoServico } from "../../../components/ModalDetailSolicitaçaoServico/ModalDetailSolicitaçaoServico";
import { removerTags } from "../../../functions/RemoveHtml/RemoveHTML";
export function SolicitacaoCadastroServico() {
  const MySwal = withReactContent(Swal);
  const [TokenDecode, setTokenDecode] = useState("");
  const { userData } = useContext(DataUserContext);
  const [erroPage, setErrorPage] = useState(true);
  const [solicitacoes, setSolicitacoes] = useState([]);
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AuthContext);
  const [ClasseData, setClasseData] = useState([]);
  const [currentItem, setCurrentItem] = useState([]);
  const [showModalDetail, setShowModalDetail] = useState(false);
  const handleCloseModalDetail = () => setShowModalDetail(false);
  const handleOpenModalDetail = () => setShowModalDetail(true);
  const optionsStatus = [
    { label: "Solicitado para Avaliação", value: 1 },
    { label: "Aprovado", value: 2 },
    { label: "Rejeitado e fechado para edição", value: 3 },
    { label: "Rejeitado e aberto para edição", value: 4 },
  ];
  useEffect(() => {
    setTokenDecode(decodeJWT(state.token));
  }, [state.token]);
  function decodeJWT(token) {
    try {
      const decoded = jose.decodeJwt(token);
      return decoded;
    } catch (error) {
      return null;
    }
  }
  async function handleFilter(event) {
    try {
      await axios
        .get(
          `${
            process.env.REACT_APP_BASEURL
          }/api/v1.0/servicos/solicitacoes?descricao=${
            event.descricao
          }&criadoPor=${
            event.criadoPor
          }&codigoUnidadeRequisitante=${""}&statusAprovacao=${
            event.status?.value ? event.status?.value : ""
          }`,
          {
            headers: {
              unidade_compradora: 227,
              Authorization: `Bearer ${state.token}`,
            },
          }
        )
        .then((res) => {
          if (res.data) {
            setSolicitacoes(res?.data);
          } else {
            setSolicitacoes([]);
          }
        });
    } catch (error) {
      ModalError(error);
    }
  }

  function RemoveItem(index) {
    MySwal.fire({
      title: "Tem certeza?",
      text: "Você não será capaz de reverter isso!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Não",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, exclua!",
    }).then((result) => {
      if (result.isConfirmed) {
        const items = [...solicitacoes];

        removeFileApi(items[index].Codigo);
        items.splice(index, 1);

        //limpa input para receber o mesmo arquivo
        setSolicitacoes(items);

        MySwal.fire("Excluído!", "O Item foi deletado.", "success");
      }
    });
  }

  function removeFileApi(code) {
    try {
      axios
        .delete(
          `${process.env.REACT_APP_BASEURL}/api/v1.0/servicos/solicitacoes/${code}`,
          {
            headers: {
              unidade_compradora: 227,
              Authorization: `Bearer ${state.token}`,
            },
          }
        )
        .then((res) => {});
    } catch (error) {
      ModalError(error);
    }
  }

  async function handleClick() {
    if (state.isAdmin) {
      navigate("/Servicos/Cadastra?Servicos=true"); //
    } else {
      navigate("/Servicos/Cadastra"); //
    }
  }
  async function getData() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/servicos/solicitacoes`,
        {
          headers: {
            unidade_compradora: 227,
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      if (response.data) {
        const filtro = response.data.filter(
          (item) => item.Status !== "Deletado"
        );
        setSolicitacoes(filtro);
      }
      // setErrorPage(true);
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: `Desculpe, ocorreu um erro ao consumir o serviço. Contate nosso suporte (ERROR ${error?.response?.status}).`,

        backdrop: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    console.log(state.decodeToken);
  }, [state]);

  function EditRequest(codeItem) {
    if (state.isAdmin) {
      navigate(`/Servicos/Avaliar-Solicitacao?Code=${codeItem}`);
    } else {
      navigate(`/Servicos/Cadastra?Code=${codeItem}&Editar=true`);
    }
  }
  function ActionRetornarButton() {
    navigate(`/Servicos`);
  }
  return (
    <>
      {erroPage ? (
        <>
          <Navbar
            link={"/Servicos"}
            NomeLink={"Catálogo de Serviços"}
            titulo={"Solicitações de cadastro - Serviços"}
            button={{ text: "+ Cadastrar nova" }}
            onClick={handleClick}
          />

          <main className="listagem-Material">
            <Formik
              initialValues={{
                descricao: "",
                criadoPor: "",
                unidadeRequisitante: "",
                status: "",
              }}
              onSubmit={handleFilter}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <div className="container pt-3 d-flex  justify-content-center   ">
                    <div className="formAdd gap-5 w-100 mb-5 mt-3 ">
                      <div className="w-25">
                        <Field
                          type="text"
                          name="descricao"
                          placeholder="Descrição"
                          className="form-control inputForm "
                        />
                      </div>
                      <div className="w-25">
                        <Field
                          type="text"
                          name="criadoPor"
                          placeholder="Criado por:"
                          className="form-control inputForm "
                        />
                      </div>
                      {/* <div className="w-25">
                        <Field
                          type="text"
                          name="unidadeRequisitante"
                          placeholder="Unidade Requisitante"
                          className="form-control inputForm "
                        />
                      </div> */}
                      <div className="w-25">
                        <Select
                          name="status"
                          options={optionsStatus}
                          placeholder="Selecione"
                          value={values.status}
                          onChange={(selectedOptions) => {
                            setFieldValue("status", selectedOptions);
                          }}
                        />
                      </div>
                      <div className="">
                        <button className="btn btn-primary " type="submit">
                          Pesquisar
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
            <div className="container">
              <table className="table">
                <tbody>
                  <tr>
                    <th>Descrição do Serviços</th>
                    <th>Criado por:</th>
                    {/* <th>Unidade Requisitante</th> */}
                    <th></th>
                    <th>Status</th>
                    <th>Ações</th>
                  </tr>
                  {solicitacoes?.map((item, index) => (
                    <tr key={item?.Codigo}>
                      <td>{removerTags(item?.Servico?.Descricao || "")}</td>
                      <td>{item.ResponsavelSolicitante?.Nome}</td>
                      <td>{item?.CodigoServico}</td>
                      <td>{item.StatusAprovacao}</td>

                      <td>
                        <div className="gap-2 d-flex">
                          {state.isAdmin ||
                          (item.ResponsavelSolicitante.CodigoUsuario ===
                            state.decodeToken?.codigo_usuario &&
                            item.StatusAprovacaoId === 4) ? (
                            <HiPencil
                              type="button"
                              name="teste"
                              onClick={() => EditRequest(item?.Codigo)}
                            />
                          ) : (
                            <></>
                          )}
                          {state.isAdmin && (
                            <MdCancel
                              className="IconImagem"
                              type="button"
                              style={{ color: "red" }}
                              onClick={() => RemoveItem(index)}
                            />
                          )}
                          <FaEye
                            type="button"
                            onClick={() => {
                              handleOpenModalDetail();
                              setCurrentItem(item?.Codigo);
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <ModalDetailSolicitaçaoServico
              showModal={showModalDetail}
              handleCloseModal={handleCloseModalDetail}
              itemId={currentItem}
            />
            <div className="d-flex flex-row-reverse">
              <Button onClick={() => ActionRetornarButton()}>voltar</Button>
            </div>
          </main>
          <Footer />
        </>
      ) : (
        <></>
      )}
    </>
  );
}
