import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Button, FormGroup } from "react-bootstrap";
import ReactQuill from "react-quill";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import * as yup from "yup";
import Footer from "../../../components/Footer/Footer";
import { ModalErrorMessage } from "../../../components/ModalErrorMessage /ModalErrorMessage ";
import { ModalJustificativa } from "../../../components/ModalJustificativa/ModalJustificativa";
import Navbar from "../../../components/Navbar/Navbar";
import AuthContext from "../../../contexts/auth";
import "./AvaliarSolicitaçaoServico.scss";
export function AvaliarSolicitaçaoServico() {
  const [Load, setLoad] = useState(false);
  const navigate = useNavigate();
  const [dataEditor, setDataEditor] = useState("");
  const inputRef = React.createRef();
  const inputImageRef = React.createRef();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { state } = useContext(AuthContext);
  const [OptionsUnidadeDeMedida, setOptionsUnidadeDeMedida] = useState([]);
  const [uploadFile, setUploadFile] = useState(false);
  const [uploadImage, setUploadImage] = useState(false);
  const [OptionsClasses, setOptionsClasses] = useState([]);
  const [OptionsSubClasses, setOptionsSubClasses] = useState([]);
  const [SubClassesData, setSubClassesData] = useState([]);
  const [SelectClasses, setSelectClasses] = useState([]);
  const [OptionsControleEspecial, setOptionsControleEspecial] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [DataServicos, setDataServicos] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [StatusAprovacao, setStatusAprovacao] = useState(1);

  const [NewInpult, setNewInpult] = useState("Nova_solicitacao");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [justificativaText, setJustificativaText] = useState("");
  const [Code, setCode] = useState("");
  const [Editar, setEditar] = useState(false);
  const MySwal = withReactContent(Swal);
  const schema = yup.object().shape({
    codigoServicos: yup
      .string()
      .typeError("O Valor deve ser um número")
      .required("O  Código  é obrigatório"),
    unidadeMedidaSelect: yup
      .object()
      .typeError("A Unidade de medida e Obrigatória")
      .required("A Unidade de medida e Obrigatória"),
    nome: yup
      .string()
      .typeError("O Nome deve ser um texto")
      .required("O Nome  é obrigatório"),
    classeSelect: yup
      .object()
      .typeError("A classe deve ser selecionada")
      .required("A classe é Obrigatória"),

    subClasseSelect: yup.object().typeError("A Subclasse deve ser selecionada"),
  });
  const schemaReprova = yup.object().shape({
    codigoServicos: yup
      .string()
      .typeError("O Valor deve ser um número")
      .required("O Código é obrigatório"),

    nome: yup
      .string()
      .typeError("O Nome deve ser um texto")
      .required("O Nome  é obrigatório"),
  });
  async function handleSubmitData(event) {
    try {
      const response = axios.put(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/servicos/solicitacoes/analisar`,
        {
          Codigo: Code,
          CodigoUnidadeRequisitante: "227",
          CodigoServico: event.codigoServicos,
          Nome: event.nome,

          InformacoesAdicionais: dataEditor,
          CodigoClasse: event.classeSelect.value,
          CodigoSubclasse: event.subClasseSelect.value,

          CodigoUnidadeMedida: event.unidadeMedidaSelect.value,
          Descontinuado: event.descontinuadoCheck,
          StatusAprovacao: StatusAprovacao,
          JustificativaReprovacao: justificativaText,
        },
        {
          headers: {
            unidade_compradora: 227,
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      // setErrorPage(true);
      ActionRetornarButton();
    } catch (error) {
      ModalErrorMessage(error);
    }
  }

  function ActionRetornarButton() {
    navigate(`/Servicos/Solicitacao-Cadastro`);
  }

  async function getDataSubClasses() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/servicos/subclasses`,
        {
          headers: {
            unidade_compradora: 227,
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      if (response.data) {
        const filtro = response.data.filter(
          (item) => item.Status !== "Deletado"
        );
        // let Options = filtro.map((item) => {
        //   return { label: item.Nome, value: item.Codigo };
        // });
        setSubClassesData(filtro);
      }
      // setErrorPage(true);
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: `Desculpe, ocorreu um erro ao consumir o serviço. Contate nosso suporte (ERROR ${error?.response?.status}).`,

        backdrop: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }
  async function getUnidadeMedida() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/servicos/unidades-de-medida`,
        {
          headers: {
            unidade_compradora: 227,
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      if (response.data) {
        const filtro = response.data.filter(
          (item) => item.Status !== "Deletado"
        );
        let Options = filtro.map((item) => {
          return { label: item.Sigla, value: item.Codigo };
        });
        setOptionsUnidadeDeMedida(Options);
      }
      // setErrorPage(true);
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: `Desculpe, ocorreu um erro ao consumir o serviço. Contate nosso suporte (ERROR ${error?.response?.status}).`,

        backdrop: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }

  async function getDataClasses() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/servicos/classes`,
        {
          headers: {
            unidade_compradora: 227,
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      if (response.data) {
        const filtro = response.data.filter(
          (item) => item.Status !== "Deletado"
        );
        let Options = filtro.map((item) => {
          return { label: item.Nome, value: item.Codigo };
        });
        setOptionsClasses(Options);
      }
      // setErrorPage(true);
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: `Desculpe, ocorreu um erro ao consumir o serviço. Contate nosso suporte (ERROR ${error?.response?.status}).`,

        backdrop: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }

  async function getData() {
    getUnidadeMedida();
    getDataClasses();
    getDataSubClasses();
  }
  async function getDataServicos(code) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/servicos/solicitacoes/${code}`,
        {
          headers: {
            unidade_compradora: 227,
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      if (response.data) {
        setDataServicos(response.data.Servico);
        setDataEditor(response.data?.Servico?.InformacoesAdicionais);
      }

      setLoad(true);
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: `Desculpe, ocorreu um erro ao consumir o serviço. Contate nosso suporte (ERROR ${error?.response?.status}).`,

        backdrop: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }
  function handleSchema() {
    if (StatusAprovacao === 3 || StatusAprovacao === 4) {
      return schemaReprova;
    } else {
      return schema;
    }
  }
  useEffect(() => {
    const code = searchParams.get("Code");
    const view = searchParams.get("view");
    if (view) {
      setEditar(true);
    } else {
      setEditar(false);
    }
    if (code !== null) {
      getData();
      getDataServicos(code);
      setCode(code);
    } else {
      setLoad(true);
      getData();
    }
  }, []);

  useEffect(() => {
    const filtro = SubClassesData?.filter(
      (item) => item.Classe.Codigo === SelectClasses.value
    );
    let Options = filtro?.map((item) => {
      return { label: item.Nome, value: item.Codigo };
    });

    setOptionsSubClasses(Options);
  }, [SelectClasses]);

  return (
    <>
      {Load ? (
        <>
          <Navbar
            // subtitulo="Módulo de Planejamento"
            NomeLink="Catálogo de Serviço"
            link="/Servicos"
            titulo="Solicitação de cadastro - Serviço"
          />

          <main className="listagem-Calendario-PCA">
            <h3 className="mt-3">Dados Gerais</h3>
            <Formik
              validationSchema={handleSchema()}
              validateOnBlur={false}
              initialValues={{
                codigoServicos: DataServicos?.CodigoServicos
                  ? DataServicos.CodigoServicos
                  : "",
                unidadeMedidaSelect: DataServicos?.UnidadeMedida
                  ? {
                      label: DataServicos.UnidadeMedida.Nome,
                      value: DataServicos.UnidadeMedida.Codigo,
                    }
                  : "",
                nome: DataServicos?.Nome ? DataServicos.Nome : "",
                classeSelect: DataServicos?.Classe
                  ? {
                      label: DataServicos.Classe.Nome,
                      value: DataServicos.Classe.Codigo,
                    }
                  : "",
                subClasseSelect: DataServicos?.Subclasse
                  ? {
                      label: DataServicos.Subclasse?.Nome,
                      value: DataServicos.Subclasse?.Codigo,
                    }
                  : "",

                descontinuadoCheck: DataServicos?.Preco?.Descontinuado
                  ? DataServicos?.Preco?.Descontinuado
                  : false,
              }}
              onSubmit={handleSubmitData}
            >
              {({ setFieldValue, values, handleSubmit }) => (
                <Form>
                  <div className="container pt-3 ">
                    <div className="formAddServicosHorizontalServico ">
                      <div className="formAddServicosVertical">
                        {state.isAdmin || Editar ? (
                          <FormGroup className="mb-3 p-1 formGroup  w-75">
                            <label className="">
                              <span className="d-flex flex-row align-items-center">
                                Código
                              </span>
                            </label>
                            <Field
                              type="text"
                              className="form-control"
                              name="codigoServicos"
                            />
                            <ErrorMessage component="p" name="codigoServicos" />
                          </FormGroup>
                        ) : (
                          <FormGroup className="mb-3 p-1 formGroup w-75">
                            <label className="">
                              <span className="d-flex flex-row align-items-center">
                                Nome
                              </span>
                            </label>
                            <Field
                              disabled={Editar}
                              type="text"
                              className="form-control"
                              name="nome"
                            />
                            <ErrorMessage component="p" name="nome" />
                          </FormGroup>
                        )}
                        <FormGroup className="mb-3 p-1 formGroup w-75">
                          <label className="">
                            <span className="d-flex flex-row align-items-center">
                              Unidade de Medida
                            </span>
                          </label>

                          <Select
                            className="inputSelect"
                            name="unidadeMedidaSelect"
                            options={OptionsUnidadeDeMedida}
                            placeholder="Selecione"
                            value={values.unidadeMedidaSelect}
                            onChange={(selectedOptions) => {
                              setFieldValue(
                                "unidadeMedidaSelect",
                                selectedOptions
                              );
                            }}
                          />
                          <ErrorMessage
                            component="p"
                            name="unidadeMedidaSelect"
                          />
                        </FormGroup>
                        <FormGroup className="mb-3 p-1 formGroup w-75">
                          <label className="">
                            <span className="d-flex flex-row align-items-center">
                              Classe
                            </span>
                          </label>
                          <Select
                            className="inputSelect"
                            name="classeSelect"
                            options={OptionsClasses}
                            placeholder="Selecione"
                            value={values.classeSelect}
                            onChange={(selectedOptions) => {
                              setFieldValue("classeSelect", selectedOptions);
                              setSelectClasses(selectedOptions);
                            }}
                          />
                          <ErrorMessage component="p" name="classeSelect" />
                        </FormGroup>
                      </div>
                      <div className="formAddServicosVertical align-items-end">
                        {(state.isAdmin || Editar) && (
                          <FormGroup className="mb-3 p-1 formGroup w-75">
                            <label className="">
                              <span className="d-flex flex-row align-items-center">
                                Nome
                              </span>
                            </label>
                            <Field
                              type="text"
                              className="form-control"
                              name="nome"
                            />
                            <ErrorMessage component="p" name="nome" />
                          </FormGroup>
                        )}
                      </div>
                      <FormGroup className="mb-3 p-1 formGroup w-75">
                        <label className="">
                          <span className="d-flex flex-row align-items-center">
                            Subclasse
                          </span>
                        </label>
                        <Select
                          className="inputSelect"
                          name="subClasseSelect"
                          options={OptionsSubClasses}
                          placeholder="Selecione"
                          value={values.subClasseSelect}
                          onChange={(selectedOptions) => {
                            setFieldValue("subClasseSelect", selectedOptions);
                          }}
                        />
                        <ErrorMessage component="p" name="subClasseSelect" />
                      </FormGroup>
                    </div>
                    <label className="">
                      <span className="d-flex flex-row align-items-center">
                        Informações Adicionais
                      </span>
                    </label>
                    <div className="mb-5">
                      <ReactQuill
                        className="sizeEditor"
                        name="text"
                        value={dataEditor}
                        onChange={(value) => {
                          setDataEditor(value);
                        }}
                      />
                    </div>
                    <div className="d-flex flex-row gap-2">
                      <Field
                        type="checkbox"
                        name="descontinuadoCheck"
                        checked={values.descontinuadoCheck}
                        onChange={(e) => {
                          const { name, checked } = e.target;
                          setFieldValue(name, checked);
                        }}
                      />
                      <label className="">
                        <span className="d-flex flex-row align-items-center">
                          Descontinuado
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="d-flex flex-row-reverse gap-3 mt-5">
                    {!Editar && (
                      <>
                        {" "}
                        <Button
                          type="submit"
                          onClick={() => setStatusAprovacao(2)}
                          disabled={uploadFile || uploadImage}
                        >
                          Aprovar
                        </Button>
                        <Button
                          className="btn-Cancel"
                          disabled={uploadFile || uploadImage}
                          onClick={() => handleShow()}
                        >
                          Rejeitar
                        </Button>
                      </>
                    )}

                    <Button onClick={() => ActionRetornarButton()}>
                      {!Editar ? "Cancelar" : "Voltar"}
                    </Button>
                  </div>
                  <ModalJustificativa
                    handleSubmit={handleSubmit}
                    setStatusAprovacao={setStatusAprovacao}
                    showModal={show}
                    handleCloseModal={handleClose}
                    justificativaText={justificativaText}
                    setJustificativaText={setJustificativaText}
                  />
                </Form>
              )}
            </Formik>
          </main>
          <Footer />
        </>
      ) : (
        <></>
      )}
    </>
  );
}
