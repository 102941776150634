import { useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import AuthContext from "../../contexts/auth";
import "./ModalJustificativa.scss";
export function ModalJustificativa({
  showModal,
  handleCloseModal,
  handleSubmit,
  setStatusAprovacao,
  justificativaText,
  setJustificativaText,
}) {
  const { state, dispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);
  function VoltarDocumento() {
    handleCloseModal();
  }
  function ReabirDocumento() {
    setStatusAprovacao(4);
    handleSubmit();
    // navigate("/Materiais/Solicitacao-Cadastro");
    handleCloseModal();
  }

  function SubmitManterRejeitado() {
    setStatusAprovacao(3);
    handleSubmit();
    // navigate("/Materiais/Solicitacao-Cadastro");
    handleCloseModal();
  }
  return (
    <Modal
      size="xl"
      show={showModal}
      onHide={handleCloseModal}
      animation={false}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton className="ModalTitle">
        <Modal.Title className="text-light">
          Detalhes do documento de Formalização da Demanda
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className=" ">
          <div className="container mt-2 mb-3">
            <h6>Justificativa da Rejeição:</h6>
          </div>
          <ReactQuill
            className="sizeEditor"
            name="text"
            value={justificativaText}
            onChange={(value) => {
              setJustificativaText(value);
            }}
          />
          <div className="d-flex justify-content-end mt-5">
            <div className=" d-flex flex-row">
              <Button
                className="m-2 btn-Cancel"
                onClick={SubmitManterRejeitado}
              >
                Manter rejeitado
              </Button>
              <Button className="m-2 btn_outline" onClick={ReabirDocumento}>
                Reabrir para edição
              </Button>
              <Button className="m-2 btn" onClick={VoltarDocumento}>
                Voltar
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
