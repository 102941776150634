import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import AuthContext from "../../contexts/auth";
import { removerTags } from "../../functions/RemoveHtml/RemoveHTML";
import "./ModalDetailSolicitaçao.scss";
export function ModalDetailSolicitaçaoServico({
  showModal,
  handleCloseModal,
  itemId,
}) {
  const { state, dispatch } = useContext(AuthContext);
  const MySwal = withReactContent(Swal);
  const [ItemData, setItemData] = useState();

  useEffect(() => {
    if (itemId !== undefined) {
      GetObjetos();
    }
  }, [itemId]);

  async function GetObjetos() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/servicos/solicitacoes/${itemId}`,

        {
          headers: {
            unidade_compradora: 227,
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      if (response?.data) {
        setItemData(response?.data);
      }
    } catch (error) {
      console.error(error);
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: `Desculpe, ocorreu um erro ao consumir o serviço. Contate nosso suporte (ERROR ${error?.response?.status}).`,

        backdrop: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }

  function ChangerModal(option) {
    handleCloseModal();
  }
  return (
    <Modal
      dialogClassName="modal-100w"
      show={showModal}
      size="xl"
      onHide={handleCloseModal}
      animation={false}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton className="ModalTitle">
        <Modal.Title className="text-light">Detalhes do Serviço</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="w-100 ">
          <div className="row w-100">
            <div className="">
              <div>
                <div className="rowContainerModal">
                  <div className="textContainer">
                    <div>
                      <p>Nome:</p>
                      {ItemData?.Servico?.Nome}
                    </div>

                    <div>
                      <p> Classe:</p>
                      {ItemData?.Servico?.Classe?.Nome}
                    </div>

                    <div>
                      <p> Unidade de Medida </p>
                      {ItemData?.Servico?.UnidadeMedida?.Sigla}
                    </div>

                    <div>
                      <p> Descrição: </p>
                      {removerTags(
                        ItemData?.Servico?.InformacoesAdicionais || ""
                      )}
                    </div>
                    {ItemData?.JustificativaReprovacao && (
                      <div>
                        <p className="text-danger">
                          {" "}
                          Justificativa da Reprovação:{" "}
                        </p>
                        {removerTags(ItemData?.JustificativaReprovacao)}
                      </div>
                    )}
                  </div>
                  <div className="textContainer">
                    <div>
                      <p> Subclasse: </p>
                      {ItemData?.Servico?.Subclasse?.Nome}
                    </div>

                    <div>
                      <p> Descontinuado: </p>
                      {ItemData?.Servico?.Descontinuado ? "sim" : "não"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
