import React, { useContext } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import AuthContext from "../contexts/auth";
import Home from "../pages/home";

import { Auth } from "../pages/Auth";
import { AddClasse } from "../pages/Classes/AddClasse/AddClasse";
import { ListaClasses } from "../pages/Classes/ListaClasses/ListaClasses";
import { AvaliarSolicitaçao } from "../pages/Materiais/AvaliarSolicitaçao/AvaliarSolicitaçao";
import { CadastroMaterial } from "../pages/Materiais/CadastroMaterial/CadastroMaterial";
import { AddControleEspecialMaterial } from "../pages/Materiais/ControleEspecialMaterial/AddControleEspecialMaterial/AddControleEspecialMaterial";
import { ListarControleEspecialMaterial } from "../pages/Materiais/ControleEspecialMaterial/ListarControleEspecialMaterial/ListarControleEspecialMaterial";
import ListaMateriais from "../pages/Materiais/ListaMateriais/ListaMateriais";
import { SolicitacaoCadastro } from "../pages/Materiais/SolicitacaoCadastro/SolicitacaoCadastro";
import { AvaliarSolicitaçaoServico } from "../pages/Servicos/AvaliarSolicitaçaoServico/AvaliarSolicitaçaoServico";
import { CadastroServico } from "../pages/Servicos/CadastroServico/CadastroServico";
import ListaServicos from "../pages/Servicos/ListaServicos/ListaServicos";
import { SolicitacaoCadastroServico } from "../pages/Servicos/SolicitacaoCadastroServico/SolicitacaoCadastroServico";
import { AddSubClasse } from "../pages/SubClasses/AddSubClasse/AddSubClasse";
import { ListaSubClasses } from "../pages/SubClasses/ListaSubClasses/ListaSubClasses";
import { AddUnidadeMedida } from "../pages/UnidadeMedida/AddUnidadeMedida/AddUnidadeMedida";
import { ListaUnidadeMedida } from "../pages/UnidadeMedida/ListaUnidadeMedida/ListaUnidadeMedida";

const AppRoutes = () => {
  const { state } = useContext(AuthContext);

  const ListRoutes = [
    {
      route: "/Materiais",
      component: <ListaMateriais />,
    },
    {
      route: "/Materiais/Cadastra",
      component: <CadastroMaterial />,
    },
    {
      route: "/Materiais/Nova-Classe",
      component: <AddClasse />,
    },
    {
      route: "/Materiais/Classes",
      component: <ListaClasses />,
    },
    {
      route: "/Materiais/Nova-Subclasse",
      component: <AddSubClasse />,
    },
    {
      route: "/Materiais/Subclasses",
      component: <ListaSubClasses />,
    },
    {
      route: "/Materiais/Nova-Unidade-Medida",
      component: <AddUnidadeMedida />,
    },
    {
      route: "/Materiais/Unidades-Medida",
      component: <ListaUnidadeMedida />,
    },
    {
      route: "/Materiais/Novo-Controle-Especial",
      component: <AddControleEspecialMaterial />,
    },
    {
      route: "/Materiais/Controle-Especial",
      component: <ListarControleEspecialMaterial />,
    },
    {
      route: "/Materiais/Solicitacao-Cadastro",
      component: <SolicitacaoCadastro />,
    },
    {
      route: "/Materiais/Avaliar-Solicitacao",
      component: <AvaliarSolicitaçao />,
    },
    // ================================ Serviços =======================================
    {
      route: "/Servicos",
      component: <ListaServicos />,
    },
    {
      route: "/Servicos/Solicitacao-Cadastro",
      component: <SolicitacaoCadastroServico />,
    },
    {
      route: "/Servicos/Avaliar-Solicitacao",
      component: <AvaliarSolicitaçaoServico />,
    },
    {
      route: "/Servicos/Cadastra",
      component: <CadastroServico />,
    },
    {
      route: "/Servicos/Nova-Classe",
      component: <AddClasse />,
    },
    {
      route: "/Servicos/Classes",
      component: <ListaClasses />,
    },
    {
      route: "/Servicos/Nova-Subclasse",
      component: <AddSubClasse />,
    },
    {
      route: "/Servicos/Subclasses",
      component: <ListaSubClasses />,
    },
    {
      route: "/Servicos/Nova-Unidade-Medida",
      component: <AddUnidadeMedida />,
    },
    {
      route: "/Servicos/Unidades-Medida",
      component: <ListaUnidadeMedida />,
    },
    {
      route: "/Servicos/Novo-Controle-Especial",
      component: <AddControleEspecialMaterial />,
    },
    {
      route: "/Servicos/Controle-Especial",
      component: <ListarControleEspecialMaterial />,
    },
  ];

  const Private = ({ children }) => {
    if (!state.isAuthenticated) {
      return <Navigate to="/" />;
    }
    return children;
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Auth />} />

        {ListRoutes.map((route, index) => (
          <Route
            exact
            key={index}
            path={route.route}
            element={<Private>{route.component}</Private>}
          />
        ))}

        <Route
          exact
          path="/Catalogo"
          element={
            <Private>
              <Home />
            </Private>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
