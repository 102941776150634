import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Button, FormGroup } from "react-bootstrap";
import ReactQuill from "react-quill";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import * as yup from "yup";
import Footer from "../../../components/Footer/Footer";
import { ModalErrorMessage } from "../../../components/ModalErrorMessage /ModalErrorMessage ";
import Navbar from "../../../components/Navbar/Navbar";
import AuthContext from "../../../contexts/auth";
import "./CadastroServico.scss";
export function CadastroServico() {
  const [Load, setLoad] = useState(false);
  const navigate = useNavigate();
  const [dataEditor, setDataEditor] = useState("");
  const inputRef = React.createRef();
  const inputImageRef = React.createRef();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showEtapa, setShowEtapa] = useState(false);
  const handleCloseEtapa = () => setShowEtapa(false);
  const { state } = useContext(AuthContext);
  const [OptionsUnidadeDeMedida, setOptionsUnidadeDeMedida] = useState([]);
  const [uploadFile, setUploadFile] = useState(false);
  const [uploadImage, setUploadImage] = useState(false);
  const [OptionsClasses, setOptionsClasses] = useState([]);
  const [OptionsSubClasses, setOptionsSubClasses] = useState([]);
  const [SubClassesData, setSubClassesData] = useState([]);
  const [SelectClasses, setSelectClasses] = useState([]);
  const [OptionsControleEspecial, setOptionsControleEspecial] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [DataServicos, setDataServicos] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [EditServicos, setEditServicos] = useState(false);
  const [NewInpult, setNewInpult] = useState(true);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [Code, setCode] = useState("");
  const [Editar, setEditar] = useState(false);
  const MySwal = withReactContent(Swal);
  const schemaAdmin = yup.object().shape({
    codigoServicos: yup
      .string()
      .typeError("O Valor deve ser um número")
      .required("O número do Código Externo é obrigatório"),
    unidadeMedidaSelect: yup
      .object()
      .typeError("A Unidade de medida e Obrigatória")
      .required("A Unidade de medida e Obrigatória"),
    nome: yup
      .string()
      .typeError("O Nome deve ser um texto")
      .required("O Nome  é obrigatório"),
    classeSelect: yup
      .object()
      .typeError("A classe deve ser selecionada")
      .required("A classe é Obrigatória"),

    subClasseSelect: yup.object().typeError("A Subclasse deve ser selecionada"),
  });
  const SchemaUser = yup.object().shape({
    nome: yup
      .string()
      .typeError("O Nome deve ser um texto")
      .required("O Nome  é obrigatório"),
  });

  async function handleSubmit(event) {
    const imagesSubmitted = selectedImages?.map((item) => {
      return item.Codigo;
    });
    const FilesSubmitted = selectedFiles?.map((item) => {
      return item.Codigo;
    });
    if (NewInpult) {
      try {
        const response = await axios.post(
          state.isAdmin
            ? `${process.env.REACT_APP_BASEURL}/api/v1.0/servicos/servicos`
            : `${process.env.REACT_APP_BASEURL}/api/v1.0/servicos/solicitacoes`,
          {
            CodigoUnidadeRequisitante: "227",
            CodigoServico: event.codigoServicos,
            Nome: event.nome,

            InformacoesAdicionais: dataEditor,
            CodigoClasse: event.classeSelect.value,
            CodigoSubclasse: event.subClasseSelect.value,

            CodigoUnidadeMedida: event.unidadeMedidaSelect.value,
            Descontinuado: event.descontinuadoCheck,
          },
          {
            headers: {
              unidade_compradora: 227,
              Authorization: `Bearer ${state.token}`,
            },
          }
        );
        // setErrorPage(true);
        navigate(`/Servicos`);
      } catch (error) {
        ModalErrorMessage(error);
      }
    } else {
      try {
        console.log(event);
        const response = await axios.put(
          EditServicos
            ? `${process.env.REACT_APP_BASEURL}/api/v1.0/servicos/servicos`
            : `${process.env.REACT_APP_BASEURL}/api/v1.0/servicos/solicitacoes/analisar`,
          {
            Codigo: Code,
            StatusAprovacao: state.isAdmin ? 3 : 1,

            CodigoUnidadeRequisitante: "227",
            CodigoServico: event.codigoServicos,
            Nome: event.nome,

            InformacoesAdicionais: dataEditor,
            CodigoClasse: event.classeSelect.value,
            CodigoSubclasse: event.subClasseSelect.value,

            CodigoUnidadeMedida: event.unidadeMedidaSelect.value,
            Descontinuado: event.descontinuadoCheck,
          },
          {
            headers: {
              unidade_compradora: 227,
              Authorization: `Bearer ${state.token}`,
            },
          }
        );
        navigate(`/Servicos`);
        // setErrorPage(true);
      } catch (error) {
        console.log(error);

        ModalErrorMessage(error);
      }
    }
  }

  function ActionRetornarButton() {
    MySwal.fire({
      title: "Tem certeza?",
      text: "Alguns dados podem não estar salvos!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Não",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, Sair!",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate(`/Servicos`);
      }
    });
  }

  async function getDataSubClasses() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/servicos/subclasses`,
        {
          headers: {
            unidade_compradora: 227,
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      if (response.data) {
        const filtro = response.data.filter(
          (item) => item.Status !== "Deletado"
        );
        // let Options = filtro.map((item) => {
        //   return { label: item.Nome, value: item.Codigo };
        // });
        setSubClassesData(filtro);
      }
      // setErrorPage(true);
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: `Desculpe, ocorreu um erro ao consumir o serviço. Contate nosso suporte (ERROR ${error?.response?.status}).`,

        backdrop: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }
  async function getUnidadeMedida() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/servicos/unidades-de-medida`,
        {
          headers: {
            unidade_compradora: 227,
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      if (response.data) {
        const filtro = response.data.filter(
          (item) => item.Status !== "Deletado"
        );
        let Options = filtro.map((item) => {
          return { label: item.Sigla, value: item.Codigo };
        });
        setOptionsUnidadeDeMedida(Options);
      }
      // setErrorPage(true);
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: `Desculpe, ocorreu um erro ao consumir o serviço. Contate nosso suporte (ERROR ${error?.response?.status}).`,

        backdrop: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }

  async function getDataClasses() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/servicos/classes`,
        {
          headers: {
            unidade_compradora: 227,
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      if (response.data) {
        const filtro = response.data.filter(
          (item) => item.Status !== "Deletado"
        );
        let Options = filtro.map((item) => {
          return { label: item.Nome, value: item.Codigo };
        });
        setOptionsClasses(Options);
      }
      // setErrorPage(true);
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: `Desculpe, ocorreu um erro ao consumir o serviço. Contate nosso suporte (ERROR ${error?.response?.status}).`,

        backdrop: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }

  async function getData() {
    getUnidadeMedida();
    getDataClasses();
    getDataSubClasses();
  }
  async function getDataServicos(code) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/servicos/servicos/${code}`,
        {
          headers: {
            unidade_compradora: 227,
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      if (response.data) {
        setDataServicos(response.data);
        setDataEditor(response.data?.InformacoesAdicionais);
        let dataRes = response.data?.EspecificacoesComplementares?.map(
          (item) => ({
            name: item?.Nome + "." + item.Extensao,
            size: item?.Tamanho,
            Codigo: item?.Codigo,
          })
        );

        setSelectedFiles(dataRes ? dataRes : []);
        let ImagensRes = response.data.Imagens?.map((item) => ({
          name: item?.Nome + "." + item.Extensao,
          size: item?.Tamanho,
          Codigo: item?.Codigo,
          Url: item?.Url,
        }));
        setSelectedImages(ImagensRes ? ImagensRes : []);
        setImagePreviews(ImagensRes ? ImagensRes : []);
      }
      setNewInpult(false);
      setLoad(true);
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: `Desculpe, ocorreu um erro ao consumir o serviço. Contate nosso suporte (ERROR ${error?.response?.status}).`,

        backdrop: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }
  async function getDataRequest(code) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/servicos/solicitacoes/${code}`,
        {
          headers: {
            unidade_compradora: 227,
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      if (response.data) {
        setDataServicos(response.data.Servicos);
        setDataEditor(response.data?.Servicos?.InformacoesAdicionais);
        let dataRes =
          response.data?.Servicos?.EspecificacoesComplementares?.map(
            (item) => ({
              name: item?.Nome + "." + item.Extensao,
              size: item?.Tamanho,
              Codigo: item?.Codigo,
            })
          );

        setSelectedFiles(dataRes ? dataRes : []);
        let ImagensRes = response.data?.Servicos?.Imagens?.map((item) => ({
          name: item?.Nome + "." + item.Extensao,
          size: item?.Tamanho,
          Codigo: item?.Codigo,
          Url: item?.Url,
        }));
        setSelectedImages(ImagensRes ? ImagensRes : []);
        setImagePreviews(ImagensRes ? ImagensRes : []);
      }
      setNewInpult(false);
      setLoad(true);
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: `Desculpe, ocorreu um erro ao consumir o serviço. Contate nosso suporte (ERROR ${error?.response?.status}).`,

        backdrop: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }
  useEffect(() => {
    console.log(state.isAdmin);
    const code = searchParams.get("Code");
    const edit = searchParams.get("Editar");
    const editServicos = searchParams.get("Servicos");
    if (editServicos) {
      setEditServicos(true);
    }
    if (edit) {
      setEditar(true);
    } else {
      setEditar(false);
    }
    if (code !== null) {
      getData();
      if (!editServicos) {
        getDataRequest(code);
      } else {
        getDataServicos(code);
      }
      setCode(code);
    } else {
      setLoad(true);
      getData();
    }
  }, []);

  useEffect(() => {
    const filtro = SubClassesData?.filter(
      (item) => item.Classe.Codigo === SelectClasses.value
    );
    let Options = filtro?.map((item) => {
      return { label: item.Nome, value: item.Codigo };
    });

    setOptionsSubClasses(Options);
  }, [SelectClasses]);

  function NavigateButtons() {
    var textButtonConfirm;
    if (state.isAdmin) {
      if (Editar) {
        textButtonConfirm = "Editar";
      } else if (Code) {
        textButtonConfirm = "Aprovar";
      } else textButtonConfirm = "Salvar";
    } else {
      if (Editar) {
        textButtonConfirm = "Editar";
      } else textButtonConfirm = "Enviar para análise";
    }

    return (
      <div className="d-flex flex-row-reverse gap-3 mt-5">
        <Button type="submit" disabled={uploadFile || uploadImage}>
          {textButtonConfirm}
        </Button>
        {Code && Editar !== true && state.isAdmin ? (
          <Button
            className="btn-Cancel"
            type="submit"
            disabled={uploadFile || uploadImage}
          >
            Rejeitar
          </Button>
        ) : (
          <></>
        )}
        <Button onClick={() => ActionRetornarButton()}>Voltar</Button>
      </div>
    );
  }
  function SelectSchemaUser() {
    if (state.isAdmin) {
      return schemaAdmin;
    } else {
      return SchemaUser;
    }
  }
  return (
    <>
      {Load ? (
        <>
          <Navbar
            // subtitulo="Módulo de Planejamento"
            NomeLink="Catálogo de Serviço"
            link="/Servicos"
            titulo="Solicitação de cadastro - Serviço"
          />

          <main className="listagem-Calendario-PCA">
            <h3 className="mt-3">Dados Gerais</h3>
            <Formik
              validationSchema={SelectSchemaUser}
              validateOnBlur={false}
              initialValues={{
                codigoServicos: DataServicos?.CodigoServicos
                  ? DataServicos.CodigoServicos
                  : "",
                unidadeMedidaSelect: DataServicos?.UnidadeMedida
                  ? {
                      label: DataServicos.UnidadeMedida.Nome,
                      value: DataServicos.UnidadeMedida.Codigo,
                    }
                  : "",
                nome: DataServicos?.Nome ? DataServicos.Nome : "",
                classeSelect: DataServicos?.Classe
                  ? {
                      label: DataServicos.Classe.Nome,
                      value: DataServicos.Classe.Codigo,
                    }
                  : "",
                subClasseSelect: DataServicos?.Subclasse
                  ? {
                      label: DataServicos.Subclasse?.Nome,
                      value: DataServicos.Subclasse?.Codigo,
                    }
                  : "",

                descontinuadoCheck: DataServicos?.Preco?.Descontinuado
                  ? DataServicos?.Preco?.Descontinuado
                  : false,
              }}
              onSubmit={handleSubmit}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <div className="container pt-3 ">
                    <div className="formAddServicosHorizontalServico ">
                      <div className="formAddServicosVertical">
                        {state.isAdmin || Editar ? (
                          <FormGroup className="mb-3 p-1 formGroup  w-75">
                            <label className="">
                              <span className="d-flex flex-row align-items-center">
                                Código
                              </span>
                            </label>
                            <Field
                              type="text"
                              className="form-control"
                              name="codigoServicos"
                            />
                            <ErrorMessage component="p" name="codigoServicos" />
                          </FormGroup>
                        ) : (
                          <FormGroup className="mb-3 p-1 formGroup w-75">
                            <label className="">
                              <span className="d-flex flex-row align-items-center">
                                Nome
                              </span>
                            </label>
                            <Field
                              disabled={Editar}
                              type="text"
                              className="form-control"
                              name="nome"
                            />
                            <ErrorMessage component="p" name="nome" />
                          </FormGroup>
                        )}
                        <FormGroup className="mb-3 p-1 formGroup w-75">
                          <label className="">
                            <span className="d-flex flex-row align-items-center">
                              Unidade de Medida
                            </span>
                          </label>

                          <Select
                            className="inputSelect"
                            name="unidadeMedidaSelect"
                            options={OptionsUnidadeDeMedida}
                            placeholder="Selecione"
                            value={values.unidadeMedidaSelect}
                            onChange={(selectedOptions) => {
                              setFieldValue(
                                "unidadeMedidaSelect",
                                selectedOptions
                              );
                            }}
                          />
                          <ErrorMessage
                            component="p"
                            name="unidadeMedidaSelect"
                          />
                        </FormGroup>
                        <FormGroup className="mb-3 p-1 formGroup w-75">
                          <label className="">
                            <span className="d-flex flex-row align-items-center">
                              Classe
                            </span>
                          </label>
                          <Select
                            className="inputSelect"
                            name="classeSelect"
                            options={OptionsClasses}
                            placeholder="Selecione"
                            value={values.classeSelect}
                            onChange={(selectedOptions) => {
                              setFieldValue("classeSelect", selectedOptions);
                              setSelectClasses(selectedOptions);
                            }}
                          />
                          <ErrorMessage component="p" name="classeSelect" />
                        </FormGroup>
                      </div>
                      <div className="formAddServicosVertical align-items-end">
                        {(state.isAdmin || Editar) && (
                          <FormGroup className="mb-3 p-1 formGroup w-75">
                            <label className="">
                              <span className="d-flex flex-row align-items-center">
                                Nome
                              </span>
                            </label>
                            <Field
                              type="text"
                              className="form-control"
                              name="nome"
                            />
                            <ErrorMessage component="p" name="nome" />
                          </FormGroup>
                        )}
                      </div>
                      <FormGroup className="mb-3 p-1 formGroup w-75">
                        <label className="">
                          <span className="d-flex flex-row align-items-center">
                            Subclasse
                          </span>
                        </label>
                        <Select
                          className="inputSelect"
                          name="subClasseSelect"
                          options={OptionsSubClasses}
                          placeholder="Selecione"
                          value={values.subClasseSelect}
                          onChange={(selectedOptions) => {
                            setFieldValue("subClasseSelect", selectedOptions);
                          }}
                        />
                        <ErrorMessage component="p" name="subClasseSelect" />
                      </FormGroup>
                    </div>
                    <label className="">
                      <span className="d-flex flex-row align-items-center">
                        Informações Adicionais
                      </span>
                    </label>
                    <div className="mb-5">
                      <ReactQuill
                        className="sizeEditor"
                        name="text"
                        value={dataEditor}
                        onChange={(value) => {
                          setDataEditor(value);
                        }}
                      />
                    </div>
                    <div className="d-flex flex-row gap-2">
                      <Field
                        type="checkbox"
                        name="descontinuadoCheck"
                        checked={values.descontinuadoCheck}
                        onChange={(e) => {
                          const { name, checked } = e.target;
                          setFieldValue(name, checked);
                        }}
                      />
                      <label className="">
                        <span className="d-flex flex-row align-items-center">
                          Descontinuado
                        </span>
                      </label>
                    </div>
                  </div>
                  {NavigateButtons()}
                </Form>
              )}
            </Formik>
          </main>
          <Footer />
        </>
      ) : (
        <></>
      )}
    </>
  );
}
